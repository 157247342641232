<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <!-- <app-header></app-header> -->

    <div class="row setmainhead">
      <mat-toolbar class="mat-toolbar-row newtopheading">
          <div class="left-col logblockinner col ">
              <mat-icon routerLink="/wallet" class="back">home</mat-icon>
              <img src="../../assets/images/logo.png">
          </div>
          <div class="col titleblock">
              <h2 class="title">Cricket</h2>
          </div>
          <div class="col text-right notificatioblok">
              <!-- <mat-icon (click)="gonotification()">notifications</mat-icon> -->
          </div>
      </mat-toolbar>
  </div>


    <div class="main-content">
      <!--New design here-->
      <div class="new-design-inplay">
        <div class="balance-info">
          <div class="show-bal-exp">
            <div class="current-bal">
              <label class="breadcrumb-item">Wallet Balance</label>
              <h3 class="breadcrumb-item" *ngIf="walletBalance">{{walletBalance| number:'1.0-0'}}</h3>
              <h3 class="breadcrumb-item" *ngIf="!walletBalance">0</h3>
              <mat-icon role="img" class="mat-icon notranslate add-btn material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font" routerLink="/wallet-deposit/deposit">add_circle</mat-icon>
            </div>
            <div class="exp-bal" tabindex="0">
              <label class="breadcrumb-item">Exposure</label>
              <h3 class="breadcrumb-item" *ngIf="exposure">{{exposure| number:'1.0-0'}}</h3>
              <h3 class="breadcrumb-item" *ngIf="!exposure">0</h3>
            </div>
            <div class="bonus-bal">
              <label class="breadcrumb-item">Bonus</label>
              <h3 class="breadcrumb-item">0</h3>
            </div>
          </div>
        </div>

        <div class="game-tab">
          <div class="tablist">
            <div class="tabitem" [ngClass]="inplaystatus==true ? 'active' : ''" (click)="marketchange('inplay')"><span>Inplay</span></div>
            <div class="tabitem" [ngClass]="upcomingstatus==true ? 'active' : ''" (click)="marketchange('upcoming')"><span>Upcoming</span></div>
          </div>
          <div class="tab-content">
            <div class="inplay-wrap-inner">
            <div class="game-card list-view"  *ngIf="upcomingstatus==true">
              <h2>Matches</h2>
              <!-- <div class="game-card-title" *ngFor="let i of virtualCricketData" (click)="virtualDetial(i?.eventId);">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time">Started at 9:30am IST</p>
              </div> -->
              <div class="game-card-title" *ngFor="let i of cricketData" (click)="matchDetail(i?.eventId)">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time">Started at  {{moment(i?.openDate).utcOffset("+05:30").format('MMM')}}
                  {{moment(i?.openDate).utcOffset("+05:30").format('D')}} <br>
                  {{moment(i?.openDate).utcOffset("+05:30").format('hh:mm a')}} IST</p>
              </div>
              <!-- <div class="game-card-title" *ngFor="let i of soccerData" (click)="matchDetail(i?.eventId)">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time">Started at  {{moment(i?.openDate).utcOffset("+05:30").format('MMM')}}
                  {{moment(i?.openDate).utcOffset("+05:30").format('D')}} <br>
                  {{moment(i?.openDate).utcOffset("+05:30").format('hh:mm a')}} IST</p>

              </div>
              <div class="game-card-title" *ngFor="let i of tennisData" (click)="matchDetail(i?.eventId)">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time">Started at  {{moment(i?.openDate).utcOffset("+05:30").format('MMM')}}
                  {{moment(i?.openDate).utcOffset("+05:30").format('D')}} <br>
                  {{moment(i?.openDate).utcOffset("+05:30").format('hh:mm a')}} IST</p>
              </div> -->
            </div>


            <div class="game-card list-view"  *ngIf="inplaystatus==true">
              <h2>Matches</h2>
              <!-- <div class="game-card-title" *ngFor="let i of virtualCricketData" (click)="virtualDetial(i?.eventId);">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time" style="color:green">Inplay</p>
              </div> -->
              <div class="game-card-title" *ngFor="let i of cricketData1" (click)="matchDetail(i?.eventId)">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time" style="color:green">Inplay</p>
              </div>
              <!-- <div class="game-card-title" *ngFor="let i of soccerData" (click)="matchDetail(i?.eventId)">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time" style="color:green">Inplay</p>
              </div>
              <div class="game-card-title" *ngFor="let i of tennisData" (click)="matchDetail(i?.eventId)">
                <h3>
                  <span class="tournament-name">{{i?.competitionName}}</span>
                  <span class="match-name">{{i?.eventName}}</span>
                </h3>
                <p class="time" style="color:green">Inplay</p>
              </div> -->
            </div>
          </div>
          </div>
        </div>

      </div>
 <!--New design here-->


    </div>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>


<button id="openModalButton" [hidden]="true" (click)="openDialog2(myModal)">Open Modal</button>
<ng-template #myModal>
    <div class="modal-body setbody">
        <mat-list class="setmat1">
            <!-- <div class="text-right"><a type="button" class="close1" data-dismiss="modal">
                    <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
                </a></div> -->
            <div class="text-right">
                <a type="button" class="close1" data-dismiss="modal">
                    <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
                </a>
               <img style="width:100%"  src="assets/images/offerpoint.png">
            </div>          
        </mat-list>
  
    </div>
  </ng-template>