<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <!-- <app-header></app-header> -->
        <div class="contianermobile">
        <div class="row setmainhead">
            <mat-toolbar class="mat-toolbar-row newtopheading">
                <div class="left-col logblockinner col ">
                    <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
                    <img src="../../assets/images/logo.png">
                </div>
                <div class="col titleblock">
                    <h2 class="title">Match</h2>
                </div>
                <div class="col text-right notificatioblok">
                    <!-- <mat-icon (click)="gonotification()">notifications</mat-icon> -->
                </div>
            </mat-toolbar>
        </div>
        <div *ngIf="lodTimer" id="overlay" style="height:100%;display:flex;align-items:center;justify-content:center;">
            <div style="font-size:7rem;" class="font-weight-bold text-warning">{{lodTimer}}</div>
        </div>

        <!-- mainClass -->
        <div class="main-card">
            <!--New Match Detail-->

            <div class="matchdetailnew" *ngIf="internetConn">
                <div class="pagetop-bar">
                    <div class="team-play-bar title-bar">
                        <div class="playing-teams">
                            <button mat-icon-button="" mattooltipclass="tournament-info" mattooltipposition="below"
                                mattooltiphidedelay="1000" tooltiptouchgestures="on"
                                class="mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base"
                                aria-describedby="cdk-describedby-message-1" cdk-describedby-host="">
                                <span class="mat-button-wrapper"> i </span>
                                <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                                <span class="mat-button-focus-overlay"></span>
                            </button>
                            <span class="">{{matchName}}</span>
                        </div>
                        <div class="playing-teams">
                            <span>{{(matchDate | date :'dd-MM-yy hh:mm aa')}}</span>
                        </div>
                    </div>
                </div>
                <div class="matchdetailtabs">
                    <div class="mat-tab-header">
                        <div class="tab-container">

                            <div class="tab-listitems">
                                <div class="tab-list-item markettab" [ngClass]="marketstatus==true ? 'active' :''" (click)="marketstatus=true;">
                                    <span>Market</span>
                                </div>
                                <div class="tab-list-item openbets" [ngClass]="marketstatus==false ? 'active' :''" (click)="marketstatus=false;">
                                    <span *ngIf="allBetData">Open Bets({{allBetData.length}})</span>
                                </div>
                                <div class="tab-list-item livetab">
                                    <button *ngIf="liveScoreStatus==true" class="livebtn"
                                        (click)="openTvDiv();liveScoreStatus=false;"><mat-icon role="img"
                                            class="mat-icon notranslate material-icons mat-icon-no-color"
                                            aria-hidden="true" data-mat-icon-type="font">live_tv</mat-icon> Live
                                        TV</button>
                                    <button *ngIf="liveTVStatus==true" id="scoreurl" class="livebtn"
                                        (click)="openScoreDiv();liveTVStatus=false;"><mat-icon role="img"
                                            class="mat-icon notranslate material-icons mat-icon-no-color"
                                            aria-hidden="true" data-mat-icon-type="font">view_week</mat-icon> Live
                                        Score</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="mat-tab-content" *ngIf="marketstatus==true">
                        <div class="mat-tab-panel" for="markettab">
                            <div class="tvlivewrapper">
                                <!-- score board and tv -->
                                <mat-card *ngIf="graphicTvUrl"
                                    style="height:345px;background-color:black;"
                                    [ngClass]="liveScoreStatus ? 'liveTV-block' : 'liveTV-none'">
                                    <mat-tab-group>
                                        <mat-tab label="Live Score">
                                            <iframe id="fp_embed_player" [src]="graphicTvUrl" marginwidth="0"
                                                marginheight="0" frameborder="0" width="100%" height="345px"
                                                scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
                                        </mat-tab>
                                    </mat-tab-group>
                                </mat-card>
                                <mat-card *ngIf="liveUrl" style="height:220px;"
                                    [ngClass]="liveTVStatus ? 'liveTV-block' : 'liveTV-none'">
                                    <mat-tab-group>
                                        <mat-tab label="Live TV">
                                            <iframe id="fp_embed_player" [src]="liveUrl" marginwidth="0"
                                                marginheight="0" frameborder="0" width="100%" height="220px"
                                                scrolling="no" allowfullscreen="allowfullscreen"
                                                allow='autoplay'></iframe>
                                        </mat-tab>
                                    </mat-tab-group>
                                </mat-card>

                                <!-- Match Odds-->

                                <div *ngIf="matchoddMarket?.length>0 && manualMatchStatus">
                                    <div class="bookmaker-card card-outer"
                                        *ngFor="let matchOddsData of matchoddMarket;trackBy: itemTrackBy;let mDTIndex=index;">
                                        <div class="head-wrap">
                                            <h2 class="card-heading">Match Odds</h2>
                                            <button mat-button=""
                                                class="mat-focus-indicator mat-button mat-button-base mat-button-disabled"
                                                *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2"
                                                disabled="true"><span class="mat-button-wrapper"
                                                    (click)="cashOut(matchOddsData)">Cashout</span><span
                                                    class="mat-button-focus-overlay"></span></button>
                                        </div>

                                        <div class="card-header">
                                            <h3 class="card-title">
                                                Min : 100 | Max : {{matchOddsData?.maxlimit}}
                                            </h3>
                                            <div class="lay-back-wrap">
                                                <h3 class="back-bg">Back</h3>
                                                <h3 class="lay-bg">Lay</h3>
                                            </div>
                                        </div>
                                        <div class="card-body"
                                            *ngFor="let runner of matchOddsData.marketBook.runners; let i = index;">
                                            <div class="data-wrap">
                                                <div class="teamlist-info flex-row-left">
                                                    <h3 class="team-title">
                                                        {{searchRunner(matchOddsData?.runners,runner?.selectionId)}}

                                                        <span class="runner-position"
                                                            [ngClass]="{'red':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)<0,'green':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)>=0}">
                                                            {{calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)|
                                                            number : '0.0-2'}}

                                                        </span>
                                                    </h3>
                                                </div>
                                                <div class="count-v-wrap" style="" *ngIf="!checkMatchOddStatus(matchOddsData,runner)">
                                                    <button *ngIf="runner?.availableToBack" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper"
                                                            (click)="getOddsValue(matchOddsData,(runner?.availableToBack?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,1,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                                            <h4>{{(runner?.availableToBack
                                                                !=undefined?runner?.availableToBack?.price :'')}}</h4>
                                                            <p>{{(runner?.availableToBack
                                                                !=undefined?(runner?.availableToBack?.size ) :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.availableToBack" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper">
                                                            <h4>-</h4>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="runner?.availableToLay" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper"
                                                            (click)="getOddsValue(matchOddsData,(runner?.availableToLay?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,0,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                                            <h4>{{(runner?.availableToLay
                                                                !=undefined?runner?.availableToLay?.price :'')}} </h4>
                                                            <p>{{(runner?.availableToLay
                                                                !=undefined?runner?.availableToLay?.size :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.availableToLay" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper">
                                                            <h4>- </h4>
                                                            <p></p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                </div>
                                                <div class="suspended-wrap"
                                                    *ngIf="checkMatchOddStatus(matchOddsData,runner)">
                                                    <h4>Suspended</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- BookMakers-->

                                <div *ngIf="bookmakerMarket?.length>0 && manualMatchStatus">
                                    <div class="bookmaker-card card-outer"
                                        *ngFor="let matchOddsData of bookmakerMarket;trackBy: itemTrackBy;let mDTIndex=index;">
                                        <div class="head-wrap">
                                            <h2 class="card-heading">BookMaker</h2>
                                            <button mat-button=""
                                                class="mat-focus-indicator mat-button mat-button-base mat-button-disabled"
                                                *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2"
                                                disabled="true"><span class="mat-button-wrapper"
                                                    (click)="cashOut(matchOddsData)">Cashout</span><span
                                                    class="mat-button-focus-overlay"></span></button>
                                        </div>

                                        <div class="card-header">
                                            <h3 class="card-title">
                                                Min : 100 | Max : {{matchOddsData?.maxlimit}}
                                            </h3>
                                            <div class="lay-back-wrap">
                                                <h3 class="back-bg">Back</h3>
                                                <h3 class="lay-bg">Lay</h3>
                                            </div>
                                        </div>
                                        <div class="card-body"
                                            *ngFor="let runner of matchOddsData.marketBook.runners; let i = index;">
                                            <div class="data-wrap">
                                                <div class="teamlist-info flex-row-left">
                                                    <h3 class="team-title">
                                                        {{searchRunner(matchOddsData?.runners,runner?.selectionId)}}

                                                        <span class="runner-position"
                                                            [ngClass]="{'red':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)<0,'green':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)>=0}">
                                                            {{calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)|
                                                            number : '0.0-2'}}

                                                        </span>
                                                    </h3>
                                                </div>
                                                <div class="count-v-wrap"  *ngIf="!checkMatchOddStatus(matchOddsData,runner)" style="">
                                                    <button *ngIf="runner?.availableToBack" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper"
                                                            (click)="getOddsValue(matchOddsData,(runner?.availableToBack?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,1,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                                            <h4>{{(runner?.availableToBack
                                                                !=undefined?runner?.availableToBack?.price :'')}}</h4>
                                                            <p>{{(runner?.availableToBack
                                                                !=undefined?(runner?.availableToBack?.size ) :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.availableToBack" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper">
                                                            <h4>-</h4>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="runner?.availableToLay" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper"
                                                            (click)="getOddsValue(matchOddsData,(runner?.availableToLay?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,0,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                                            <h4>{{(runner?.availableToLay
                                                                !=undefined?runner?.availableToLay?.price :'')}} </h4>
                                                            <p>{{(runner?.availableToLay
                                                                !=undefined?runner?.availableToLay?.size :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.availableToLay" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper">
                                                            <h4>- </h4>
                                                            <p></p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                </div>
                                                <div class="suspended-wrap"
                                                    *ngIf="checkMatchOddStatus(matchOddsData,runner)">
                                                    <h4>Suspended</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- ToWinToss Market-->

                                <div *ngIf="toWinTossMarket?.length>0 && manualMatchStatus">
                                    <div class="bookmaker-card card-outer"
                                        *ngFor="let matchOddsData of toWinTossMarket;trackBy: itemTrackBy;let mDTIndex=index;">
                                        <div class="head-wrap">
                                            <h2 class="card-heading">ToWinToss</h2>
                                            <button mat-button=""
                                                class="mat-focus-indicator mat-button mat-button-base mat-button-disabled"
                                                *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2"
                                                disabled="true"><span class="mat-button-wrapper"
                                                    (click)="cashOut(matchOddsData)">Cashout</span><span
                                                    class="mat-button-focus-overlay"></span></button>
                                        </div>

                                        <div class="card-header">
                                            <h3 class="card-title">
                                                Min : 100 | Max : {{matchOddsData?.maxlimit}}
                                            </h3>
                                            <div class="lay-back-wrap">
                                                <h3 class="back-bg">Back</h3>
                                                <h3 class="lay-bg">Lay</h3>
                                            </div>
                                        </div>
                                        <div class="card-body"
                                            *ngFor="let runner of matchOddsData.marketBook.runners; let i = index;">
                                            <div class="data-wrap">
                                                <div class="teamlist-info flex-row-left">
                                                    <h3 class="team-title">
                                                        {{searchRunner(matchOddsData?.runners,runner?.selectionId)}}

                                                        <span class="runner-position"
                                                            [ngClass]="{'red':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)<0,'green':calProLoss(matchOddsData.runners,matchOddsData.marketBook,i,matchOddsData)>=0}">
                                                            {{calProLoss(matchOddsData?.runners,matchOddsData?.marketBook,i,matchOddsData)|
                                                            number : '0.0-2'}}

                                                        </span>
                                                    </h3>
                                                </div>
                                                <div class="count-v-wrap"  *ngIf="!checkMatchOddStatus(matchOddsData,runner)" style="">
                                                    <button *ngIf="runner?.availableToBack" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper"
                                                            (click)="getOddsValue(matchOddsData,(runner?.availableToBack?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,1,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                                            <h4>{{(runner?.availableToBack
                                                                !=undefined?runner?.availableToBack?.price :'')}}</h4>
                                                            <p>{{(runner?.availableToBack
                                                                !=undefined?(runner?.availableToBack?.size ) :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.availableToBack" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper">
                                                            <h4>-</h4>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="runner?.availableToLay" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper"
                                                            (click)="getOddsValue(matchOddsData,(runner?.availableToLay?.price),runner?.selectionId,matchOddsData?.marketId,matchOddsData?.marketName,matchOddsData?.eventName,0,searchRunner(matchOddsData?.runners,runner?.selectionId),0);">
                                                            <h4>{{(runner?.availableToLay
                                                                !=undefined?runner?.availableToLay?.price :'')}} </h4>
                                                            <p>{{(runner?.availableToLay
                                                                !=undefined?runner?.availableToLay?.size :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.availableToLay" mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper">
                                                            <h4>- </h4>
                                                            <p></p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                </div>
                                                <div class="suspended-wrap"
                                                    *ngIf="checkMatchOddStatus(matchOddsData,runner)">
                                                    <h4>Suspended</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- Fancy Market-->

                                <div *ngIf="sessionMarket?.length>0 && manualMatchStatus">
                                    <div class="bookmaker-card card-outer"
                                        *ngFor="let matchOddsData of matchoddMarket;trackBy: itemTrackBy;let mDTIndex=index;">
                                        <div class="head-wrap">
                                            <h2 class="card-heading">ToWinToss</h2>
                                            <!-- <button mat-button=""
                                            class="mat-focus-indicator mat-button mat-button-base mat-button-disabled"
                                            *ngIf="matchOddsData.marketName!='TO Win Toss' && matchOddsData?.runners.length==2"
                                            disabled="true"><span class="mat-button-wrapper"
                                                (click)="cashOut(matchOddsData)">Cashout</span><span
                                                class="mat-button-focus-overlay"></span></button> -->
                                        </div>

                                        <div class="card-header">
                                            <h3 class="card-title">
                                                Min : {{runner?.minlimit}} | Max : {{runner?.maxlimit}}
                                            </h3>
                                            <div class="lay-back-wrap">
                                                <h3 class="back-bg">No</h3>
                                                <h3 class="lay-bg">Yes</h3>
                                            </div>
                                        </div>
                                        <div class="card-body"
                                            *ngFor="let runner of sessionMarket;trackBy: itemTrackBy;let mDTIndex=index;">
                                            <div class="data-wrap">
                                                <div class="teamlist-info flex-row-left">
                                                    <h3 class="team-title">{{runner?.marketName}}
                                                        <span class="runner-position" *ngIf="sessionMarketExposure">
                                                            <span
                                                                *ngIf="sessionMarketExposure[runner.marketId]">{{sessionMarketExposure[runner.marketId]}}</span>
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div class="count-v-wrap" style="" *ngIf="runner?.marketBook?.statusLabel!='SUSPENDED' && runner?.marketBook?.status=='OPEN'">
                                                    <button *ngIf="runner?.marketBook?.availableToLay"
                                                        mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper"
                                                            (click)="setSessionValue(runner?.marketBook?.availableToLay?.price,runner?.marketId,runner?.marketName,runner?.eventName,0,runner?.marketBook?.availableToLay?.size)">
                                                            <h4>{{(runner?.marketBook?.availableToLay
                                                                !=undefined?runner?.marketBook?.availableToLay?.price
                                                                :'')}}
                                                            </h4>
                                                            <p>{{(runner?.marketBook?.availableToLay
                                                                !=undefined?runner?.marketBook?.availableToLay?.size
                                                                :'')}}
                                                            </p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.marketBook?.availableToLay"
                                                        mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base lay-count count-value">
                                                        <span class="mat-button-wrapper">
                                                            <h4>- </h4>
                                                            <p></p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="runner?.marketBook?.availableToBack"
                                                        mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper"
                                                            (click)="setSessionValue(runner?.marketBook?.availableToBack?.price,runner?.marketId,runner?.marketName,runner?.eventName,1,runner?.marketBook?.availableToBack?.size)">
                                                            <h4>{{(runner?.marketBook?.availableToBack
                                                                !=undefined?runner?.marketBook?.availableToBack?.price
                                                                :'')}}</h4>
                                                            <p>{{(runner?.marketBook?.availableToBack
                                                                !=undefined?(runner?.marketBook?.availableToBack?.size )
                                                                :'')}}</p>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                    <button *ngIf="!runner?.marketBook?.availableToBack"
                                                        mat-flat-button=""
                                                        class="mat-focus-indicator mat-flat-button mat-button-base count-value back-count">
                                                        <span class="mat-button-wrapper">
                                                            <h4>-</h4>
                                                        </span>
                                                        <span matripple="" class="mat-ripple mat-button-ripple"></span>
                                                        <span class="mat-button-focus-overlay"></span>
                                                    </button>
                                                </div>
                                                <div class="suspended-wrap"
                                                    *ngIf="runner?.marketBook?.statusLabel=='Ball Running'">
                                                    <h4>Ball runnig</h4>
                                                </div>
                                                <div class="suspended-wrap"
                                                    *ngIf="runner?.marketBook?.statusLabel==='SUSPENDED' && runner?.marketBook?.status!='OPEN'">
                                                    <h4>Suspended</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mat-tab-content" *ngIf="marketstatus==false">
                        <div class="betList" *ngIf="allBetDataLength != 0">
                            <ng-container *ngFor="let bet of allBetData;let i= index;">
                              <div class="list" fxLayout="column" fxLayoutAlign="center space-around "
                                  [ngClass]="{'lay-rate':(bet?.type)=='Lay','back-rate':(bet?.type)=='Back'}">
                                  <div fxLayout="row" fxLayoutAlign="space-between center">
                                      <span>Selection Name :{{bet?.selectionName}}</span>
                                      <span>Odds: {{bet?.marketName}}</span>
                      
                                  </div>
                                  <div fxLayout="row" fxLayoutAlign="space-between center">
                                      <span *ngIf="(bet?.type)=='Lay'"
                                          [ngClass]="{'pink':(bet?.type)=='Lay','blue':(bet?.type)=='Back'}">Lay</span>
                                      <span *ngIf="(bet?.type)=='Back'"
                                          [ngClass]="{'pink':(bet?.type)=='Lay','blue':(bet?.type)=='Back'}">Back</span>
                                      <span>Stack: {{bet?.stake}}</span>
                      
                                  </div>
                                  <div fxLayout="row" fxLayoutAlign="space-between center">
                                      <span>{{moment(bet?.placedTime).utcOffset("+05:30").format('YYYY-MM-DD hh:mm a')}}</span>
                                      <span>P&L: <a>{{(bet?.rate)| number : '0.0-2'}}</a></span>
                                  </div>
                                  <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                                      <span>{{bet?.marketName}}</span>
                                      <span *ngIf="bet.delete_status =='2'">Status: <a><button class="btn btn-danger">Void</button></a></span>
                                  </div> -->
                              </div>
                          </ng-container>
                          </div>
                          <div _ngcontent-ssv-c93="" class="no-data ng-star-inserted" *ngIf="allBetDataLength == 0">
                            <p _ngcontent-ssv-c93="">No bets placed yet, Place your bet now!</p>
                         </div>
                    </div>
                </div>
            </div>


            <!--New Match Detail End-->



            <!-- internetDisscont -->
            <div *ngIf="!internetConn" class="col-12" style="margin-top:38vh;">
                <h4 class="font-weight-bold text-center text-warning">Please check your internet connection and try
                    again</h4>
            </div>
        </div>
    </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #addMarket>
    <div class="modal-header">
        <h4 class="modal-title">Market List</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><mat-icon>clear</mat-icon></a>
    </div>

    <div class="modal-body">
        <div class="body-inner">
            <div class="sport-market-list" *ngFor="let homeMatch of homeData | keyvalue;let i = index;">
                <h3><img src="assets/icons/{{homeMatch?.key}}.png"> {{homeMatch?.key}}</h3>
                <table class="table">
                    <tbody>
                        <tr *ngFor="let matchData of homeMatch.value|orderBy : 'match_date';let j = index;"
                            (click)="addOtherMarket(matchData)">
                            <td class="market-name">
                                <div class="event-info">
                                    <div class="event-format-strip">
                                        <div *ngIf="matchData?.inplay == true" class="inplay-div">
                                            <div class="inplay-btn inplay"><span>InPlay</span> </div>
                                        </div>
                                        <div class="event-full-date">
                                            <span
                                                class="date">{{moment(matchData?.match_date).utcOffset("+05:30").format('D')}}
                                                {{moment(matchData?.match_date).utcOffset("+05:30").format('MMM')}}
                                                {{moment(matchData?.match_date).utcOffset("+05:30").format('HH:mm')}}
                                                IST</span>
                                        </div>
                                        <span class="event-format-series">{{matchData?.series_name}}</span>
                                    </div>
                                    <div class="event-team">
                                        <div class="team-name">{{matchData?.match_name}}</div>
                                    </div>
                                </div>

                            </td>
                            <td class="text-right"><mat-icon>add</mat-icon></td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    </div>

    <!-- <div class="modal-footer">
        <a class="modal-close red_button btn" data-dismiss="modal" (click)="modalRef.hide()">Close</a>
    </div> -->
</ng-template>

<ng-template #bets>
    <div class="modal-header">
        <h4 class="modal-title">Bet List of "{{selectedMarket}}"</h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <div class="modal-body no-scroll-body">
        <div class="body-inner">
            <div class="betFilter">
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="allBetRadio" [checked]="true"
                        value="all" [(ngModel)]="selectedPopAllBetFilter"
                        (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="allBetRadio">All</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="backBet" value="1"
                        [(ngModel)]="selectedPopAllBetFilter"
                        (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="backBet">Back</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="layBet" value="0"
                        [(ngModel)]="selectedPopAllBetFilter"
                        (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="layBet">Lay</label>
                </div>
                <!-- <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="deleteBet">
                    <label class="form-check-label" for="deleteBet">Delete Bet</label>
                </div>  -->
            </div>
            <form>
                <table class="table">
                    <thead>
                        <tr>
                            <!-- <th>ID</th> -->
                            <th>Selection</th>
                            <th>Rate</th>
                            <th>Stack</th>
                            <th>P|L</th>
                            <th>Type</th>
                            <th>Placed Time</th>
                        </tr>
                        <!-- <tr>
                            <th>
                                <input type="text" placeholder="ID" name="userid" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Name" name="username" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Selection" name="selection" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Rate" name="rate" class="form-control">
                            </th>
                            <th>
                                <input type="text" placeholder="Stack" name="stack" class="form-control">
                            </th>
                            <th>
                                -   
                            </th>
                            <th>
                                <input type="text" placeholder="Placed Time" name="placedTime" class="form-control">
                            </th>
                        </tr> -->
                    </thead>
                    <tbody *ngIf="betDataPopup == true">
                        <tr *ngFor="let bet of allPopBetData;let i = index;">
                            <!-- <td>{{i+1}}</td> -->
                            <td>{{(bet?.is_fancy == 1) ? (bet?.market_name) : (bet?.selection_name)}}</td>
                            <td>{{bet?.odds}}</td>
                            <td>{{bet?.stack}}</td>
                            <td [ngClass]="{'red':(bet?.p_l)<0,'green':(bet?.p_l)>0}">{{(bet?.p_l)| number : '0.0-2'}}
                            </td>
                            <td [ngClass]="{'lay-rate':(bet?.is_back)==0,'back-rate':(bet?.is_back)==1}">{{bet?.is_back
                                == 1 ? 'Back' : 'Lay'}}</td>
                            <td>{{(bet?.createdAt | date :'dd-MM-yyyy hh:mm:ss aa')}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="betDataPopup == false">
                        <tr>
                            <td colspan="6" class="text-center">{{message}}</td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <div class="text-end">
            <button type="submit" class="btn btn-success">Submit</button>
        </div>
    </div> -->
</ng-template>
<ng-template #Terms>
    <div class="modal-content custom-modal-main">
        <div class="modal-header">
            <h4 class="modal-title">Terms & Conditions</h4>
            <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i
                    class="fa fa-times"></i></a>
        </div>

        <div class="modal-body">
            <p>AAJ SE TIED MATCH <br> ME FANCY KA LEN <br>DEN KARNGE.</p>
            <p>1.If you not accept this agreement do not place any bet.</p>
            <p>2.Cheating bets deleted automatically or manual. No Claim.</p>
            <p>3.Admin decision is final and no claim on it.</p>
            <p>4. Batsman Runs (In-Play) Over/Under (back/lay) runs bets will stand after batsman has faced one ball or
                is given out before first ball is faced. Score counts if batsman is Not-Out including if innings is
                declared. In case of rain, match abandoned etc. settled bets will be valid.</p>
            <p>5.Current/Next Innings Runs Odd/Even Extras and Penalty runs will be included for settlement purposes.
            </p>
            <p>6.Runs at Fall of 1st Wicket At least one delivery must be bowled, if no wickets fall bets will be void
                unless settlement is already determined.</p>
            <p>7.Runs at Fall of Next Wicket The total innings runs scored by a team before the fall of the specified
                wicket determines the result of the market. If a team declares or reaches their target then the total
                amassed will be the result of the market. Bets will be void should no more play take place following the
                intervention of rain, or any other delay, as the ability to reach previous quotes offered will have been
                removed . In case of rain, match abandoned etc. settled bets will be valid.</p>
            <p>8.We do not accept manual bet.</p>
            <p>9.In case of anyone found using two different IDs logged in same IP address his winning in both account
                will be cancelled.</p>
            <p>10.In case of cheating and betting in unfair rates we will cancel the bet even after settling.</p>
            <p>11.Local fancy are based on Haar - Jeet.</p>
            <p>12.Incomplete session will be cancelled but complete session will be settled.</p>
            <p>13.In case of match abandoned, cancelled, no result etc. completed sessions will be settled.</p>
            <p>14.Lambi Paari : In 20-20 match entire twenty overs should be bowled, in case of rain or any delay if
                even one over is deducted the bets will be cancelled. In One Day match entire 50 overs should be bowled,
                in case of rain or any delay if even one over is deducted the bets will be cancelled.</p>
            <p>15.Advance Session,Lambi 1`st Inning Valid Only</p>
            <p>16.Total Match Four ,Total Match Siixes,Total Match Runs,Total Match Wides,Total Match Wicket. If Over
                Gets Less Then All BET Will Cancel This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game
            </p>
            <p>17.1st Over Total Runs Prices will be offered for the total runs scored during the 1st over of the match.
                Extras and penalty runs will be included. The over must be completed for bets to stand unless settlement
                is already determined.</p>
            <p>18.Limited Overs Matches - Individual Batsmen Runs or Partnerships In a limited overs match where an
                individual batsman or partnership runs are traded in-play and the innings is curtailed or subject to any
                reduction in overs, then these markets will be settled at the midpoint of the last available quote
                before the overs were reduced. If the innings resumes at a later time, a new market may be formed. If a
                client wants a position in the new market they are required to place a new trade. If there are any
                subsequent reductions in overs, exactly the same rules will continue to apply i.e. the market is settled
                at the midpoint of the last available quote before the overs were reduced and a new market may be
                formed.</p>
            <p>19.Test Matches - Individual Batsmen Runs / Partnerships All bets, open or closed, on an individual
                batsman or partnership runs shall be void if 50 full overs are not bowled unless one team has won, is
                dismissed or declares prior to that point. Bets on partnership totals make up when the next wicket
                falls. If a batsman in the relevant partnership retires hurt, the partnership is treated as continuing
                between the remaining batsman and the batsman who comes to the wicket. A partnership is also treated as
                being ended by the end of an innings.</p>
            <p>20.Trading bets are not allowed.</p>

        </div>

        <div class="modal-footer">
            <a href="javascript:void(0)" class="modal-close red_button btn" data-dismiss="modal"
                (click)="modalRef.hide()">Close</a>
        </div>
    </div>
</ng-template>

<!-- sessionBook bets -->
<ng-template #sessionBook>
    <div class="modal-header model_header bg-warning">
        <h4 class="modal-title">Run Position</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()">close</mat-icon>
        </a>
    </div>

    <div class="modal-body" style="height:230px">
        <div class="table-div modeltable">
            <table class="table table-bordered">
                <tr>
                    <th class="text-center text-warning" align="center">Run</th>
                    <th class="text-right text-warning" align="right">Amount</th>
                </tr>
                <tr *ngFor="let l of sessionrunnerProfit|keyvalue" [ngClass]="l.value > 0 ? 'back' : 'lay'">
                    <td align="center">{{l.key}}</td>
                    <td align="right">{{l.value}}</td>
                </tr>
            </table>
            <button style="float:right" class="bg-danger text-white" (click)="modalRef.hide()">Close</button>
        </div>
    </div>
</ng-template>

<ng-template #fullwidth>
    <div class="modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <div class="text-right">
                    <a type="button" class="close1" data-dismiss="modal">
                        <mat-icon (click)="modalRef.hide()">close</mat-icon>
                    </a>
                </div>
            </div>
            <div class="modal-body set-modal">
                <iframe width="100%" height="100%"
                    src="https://www.glivestreaming.com/livetv/8/velki/velki/17039569/sohag5747/34d8b21c3b86f2e97f2730781cfff381/103.205.114.199/1699357970?=&lang=en"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</ng-template>