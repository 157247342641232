<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <!-- <app-header></app-header> -->

    <div class="contianermobile">
     
      <div class="row setmainhead">
        <div class="col-12">
          <h2 class="title">
            <mat-icon (click)="sidenavLeft.toggle()" class="order">menu</mat-icon>
            <ng-container *ngIf="!loginButtnStatus">
              <button style="float:right" mat-stroked-button [routerLink]="['/register']" class="color-white">
                 <mat-icon>how_to_reg</mat-icon>Join Now<br>
              </button>
              <button style="float:right" mat-stroked-button [routerLink]="['/login']" class="color-white">
                 <mat-icon>login</mat-icon> login<br>
              </button>
             </ng-container>
            <span class="headicon" *ngIf="loginButtnStatus">
              <mat-icon [routerLink]="['/wallet-notifications']">notifications</mat-icon>
            </span>
          </h2>
        </div>



        <div class="new-design-wallet">
          <div class="wallet-col">
            <div class="deopwit-col">
              <span (click)="deposit()" class="inactive"> <span>Deposit</span><img src="assets/images/arrow-up.svg">
                </span>
            </div>
            <div class="deopwit-blnc-col">
              <div class="logowallet"><img src="assets/images/logo.svg"></div>
              <h4>
                <span>Wallet Balance </span>
                <span *ngIf="userwalletBalance" class="amount"><img src="assets/images/coins-icon.png"> {{userwalletBalance| number : '0.0-2'}}</span>
                <span *ngIf="!userwalletBalance" class="amount"><img src="assets/images/coins-icon.png"> 00</span>
              </h4>
              <!-- <h4>
                <span> Exposure </span>
                <span *ngIf="userwalletexposer">{{userwalletexposer| number : '0.0-2'}}</span>
                <span *ngIf="!userwalletexposer">00</span>
              </h4> -->
            </div>
            <div class="deopwit-col">
              <span (click)="withdraw()" class="inactive"> <span>withdraw</span>  <img src="assets/images/arrow-down.svg">
               </span>
            </div>
          </div>
        </div>


      </div>

      <div class="grey-acc-section">
        <!-- <div class="slider-sec">
          <div class="slider-item">
            <img src="https://ss.manage90.com/go-punt/banner/1707715041346_246820_TCMBGFZUGYHVXSXSGGEQFYVWJ.png">
            </div>
        </div> -->
              <!-- banner -->
      <div id="demo" class="carousel slide" data-ride="carousel">
        <!-- Indicators -->
        <ul class="carousel-indicators">
          <li data-target="#demo" data-slide-to="0" class="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
          <li data-target="#demo" data-slide-to="3"></li>
          <li data-target="#demo" data-slide-to="4"></li>
          <li data-target="#demo" data-slide-to="5"></li>
        </ul>

        <!-- The slideshow -->
        <div class="carousel-inner">

          <div class="carousel-item active">
            <img src="https://kushubmedia.com/build/new-osg-app/slider/1.png" alt="Chicago" height="140">
          </div>

          <div class="carousel-item">
            <img src="https://kushubmedia.com/build/new-osg-app/slider/2.png" alt="Chicago" height="140">
          </div>
          <div class="carousel-item">
            <img src="https://kushubmedia.com/build/new-osg-app/slider/3.png" alt="New York" height="140">
          </div>
          <div class="carousel-item">
            <img src="https://kushubmedia.com/build/new-osg-app/slider/4.png" alt="Los Angeles" height="140">
          </div>
          <div class="carousel-item">
            <img src="https://kushubmedia.com/build/new-osg-app/slider/5.png" alt="Chicago" height="140">
          </div>
          <div class="carousel-item">
            <img src="https://kushubmedia.com/build/new-osg-app/slider/6.png" alt="New York" height="140">
          </div>
        </div>

        <!-- Left and right controls -->
        <a class="carousel-control-prev" href="#demo" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#demo" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
      <!-- bannerEnd -->


        <div class="matched-section">
          <div class="matchtitle  mb-2">
            <div class="row align-items-center">
              <div class="col-6"><h2>Matches</h2></div> 
              <div class="col-6 text-right"> <a [routerLink]="['/dashboard']" class="viewall_link"> View All </a></div>
            </div>
          </div>
          <div class="game-card list-view" *ngIf="cricketData">
            <div  class="game-card-title" *ngFor="let i of cricketData">
              <div  class="card-wrap"><img  alt="" src="assets/images/icon-match.svg">
                <h3>
                  <span  class="tournament-name">{{i?.competitionName}}</span>
                  <span  class="match-name">{{i?.eventName}}</span>
                  <span  class="status green-text">inplay</span>
                </h3>
              </div>
                <a  class="play-btn" color="" (click)="matchDetail(i?.eventId)">Click to play</a>
              </div>
            <!-- <div  class="game-card-title" *ngFor="let i of soccerData">
              <div  class="card-wrap"><img  alt="" src="assets/images/icon-match.svg">
                <h3>
                  <span  class="tournament-name">{{i?.competitionName}}</span>
                  <span  class="match-name">{{i?.eventName}}</span>
                  <span  class="status green-text">inplay</span>
                </h3>
              </div>
                <a  class="play-btn" color="" (click)="matchDetail(i?.eventId)">Click to play</a>
              </div> -->
            <!-- <div  class="game-card-title" *ngFor="let i of tennisData">
              <div  class="card-wrap"><img  alt="" src="assets/images/icon-match.svg">
                <h3>
                  <span  class="tournament-name">{{i?.competitionName}}</span>
                  <span  class="match-name">{{i?.eventName}}</span>
                  <span  class="status green-text">inplay</span>
                </h3>
              </div>
                <a  class="play-btn" color="" (click)="matchDetail(i?.eventId)">Click to play</a>
              </div> -->
          </div>
        </div>
        

        <div class="casino-section">
          <div class="casinotitle mb-2">
            <div class="row align-items-center">
              <div class="col-6"><h2>Casino</h2></div> 
              <div class="col-6 text-right"></div>
            </div>
          </div>

          <div class="casinosldier">
            <div class="casinocontent-box">
              <div class="casinoimg">
                <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
                <img src="assets/casino/evo_dragontiger.jpg">
                <p class="gamename">Dragon Tiger</p>
              </div>
            </div>
            <div class="casinocontent-box">
            <div class="casinoimg">
              <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
              <img src="assets/casino/evo_livespeedbaccarata.jpg">
              <p class="gamename">Speed Baccarat A</p>
            </div>
          </div>
          <div class="casinocontent-box">
            <div class="casinoimg">
              <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
              <img src="assets/casino/evo_livespeedbaccaratb.jpg">
              <p class="gamename">Speed Baccarat B</p>
            </div>
          </div>
          <div class="casinocontent-box">
            <div class="casinoimg">
              <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
              <img src="assets/casino/evo_livespeedbaccaratc.jpg">
              <p class="gamename">Speed Baccarat C</p>
            </div>
          </div>
          <div class="casinocontent-box">
            <div class="casinoimg">
              <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
              <img src="assets/casino/evo_supersicbo.jpg">
              <p class="gamename">Super Sic Bo</p>
            </div>
          </div>
          <div class="casinocontent-box">
            <div class="casinoimg">
              <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
              <img src="assets/casino/baccarat-nocommision-bg.jpg">
              <p class="gamename">Peek Baccarat</p>
            </div>
          </div>
          </div>

          <div class="createbtnwrap">
            <a [routerLink]="['/wallet-idx']" class="create"><img src="assets/images/finger-right-icon.svg"> <mat-icon>add_circle</mat-icon> Create </a>
          </div>
          </div>
         
      </div>

<!--Old code start here-->
      <div class="row setrow" hidden>
        <!-- <div class="col-3 text-center align"><span (click)="deposit()" class="inactive"><mat-icon>save_alt</mat-icon>
            <br>Deposit</span></div>
        <div class="col-3 text-center align"><span (click)="withdraw()" class="inactive"><mat-icon> money</mat-icon>
            <br>withdraw</span></div> -->
        <div class="col-6 text-center align"><span (click)="Ids()" class="inactive"><mat-icon>account_circle</mat-icon>
            <br>IDs</span></div>
        <div class="col-6 text-center align"><span [routerLink]="['/wallet-passbook']" class="inactive"><i
              class="material-icons">account_balance_wallet</i>
            <br>Passbook</span></div>
      </div>
      <div class="row mainsetrow" hidden>
        <div class="col-12">
          <h2>Related Links</h2>
        </div>
        <!-- <div class="col-3 text-center"><span (click)="goOffer()" class="inactive"><mat-icon>
              bubble_chart</mat-icon> <br>Offers</span></div> -->
        <div class="col text-center"><span [routerLink]="['/wallet-notifications']"
            class="inactive"><mat-icon>notifications_active</mat-icon>
            <br>Notifications</span></div>
        <div class="col text-center"><span  [routerLink]="['/wallet-withdraw-details']" class="inactive"><mat-icon>payment</mat-icon>
            <br>Withdraw Details</span></div>
        <div class="col text-center" *ngIf="support_nO"><a href="https://wa.me/{{support_nO}}"
            class="inactive"><mat-icon>perm_device_information</mat-icon>
            <br>whatsApp Support</a></div>
      </div>

      <div class="row mainsetrow1" *ngIf="transactions?.length!=0" hidden>
        <div class="col-12">
          <h2 class="setpenhed">Pending Request<span class="all" [routerLink]="['/wallet-passbook']">See All</span></h2>
          <div id="demo" class="carousel slide my-2" data-ride="carousel" *ngIf="transactions?.length!=0">
            <!-- The slideshow -->
            <div class="carousel-inner">
              <div class="carousel-item" [ngClass]="{'active': i == 0}"
                *ngFor="let tdata of transactions;let i = index" [routerLink]="['/wallet-passbook']">
                <div class="row setheadid">
                  <div class="col-3">
                    <div class="text-success">
                      <img *ngIf="!tdata.sites" src="assets/logo/logo_1.png" class="iconzo1">
                      <img *ngIf="tdata.sites"
                        src="https://acepunt.kushubmedia.com/uploads/sites/{{tdata?.sites?.image}}" class="setimage">
                    </div>
                  </div>
                  <div class="col-9 setcolumn">
                    <b class="p-0" *ngIf="tdata.type!='Withdrawal'">
                      <span Class="settext1">{{tdata?.type}} <span> To {{tdata?.to}}</span></span>
                      <span
                        style="float:right;color: #fff;letter-spacing: 1px;font-weight: 400;">{{tdata?.amount}}</span></b>
                    <b class="p-0" *ngIf="tdata.type=='Withdrawal'">
                      <span Class="settext1">{{tdata?.type}} <span> From {{tdata?.to}}</span></span>
                      <span
                        style="float:right;color: #fff;letter-spacing: 1px;font-weight: 400;">{{tdata?.amount}}</span></b>
                    <p class="setremark1">
                      <!-- <span>{{tdata?.createdAt|date:'medium'}}</span> -->
                      <span>{{tdata?.createdAt|date:'d MMM yy h:mm a'}}</span>
                      <span  style="float:right;color: #00804a;" [ngClass]="tdata?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{tdata?.status}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="transactions?.length==0">
            <p class="setno">No Request Found</p>
          </div>
        </div>
      </div>


      <!-- <div class="row mainsetrow1" *ngIf="offerstatus">
        <div class="col-12">
          <h2>Offers <span class="all" (click)="deposit()">See All</span></h2>
          <div class="row firstoffer" *ngIf="offerstatus">
            <div class="col-3">
              <img class="setoimg" src="assets/images/offers.png">
            </div>
            <div class="col-9" (click)="deposit()">
              <h3>{{offerstatus?.name}}</h3>
              <p>Get {{offerstatus?.percentage}}% bonus {{offerstatus?.description}}</p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row mainsetrow2" *ngIf="myid?.length!=0" hidden>
        <div class="col-12">
          <h2>My Ids <span class="all" (click)="Ids()">View All</span></h2>
        </div>
        <div class="col-12">
          <div id="demo" class="carousel slide my-2" data-ride="carousel">
            <!-- The slideshow -->
            <div class="carousel-inner">
              <div class="carousel-item" [ngClass]="{'active': i == 0}" *ngFor="let x of myid;let i = index">
                <div class="row setheadid">
                  <div class="col-2 text-center">
                    <img src="https://acepunt.kushubmedia.com/uploads/sites/{{x?.sites?.image}}" alt="Chicago"
                      class="setimage">
                  </div>
                  <div class="col-10">
                    <a class="setpera" href="https://{{x?.sites?.url}}" target="_blank">{{x?.sites?.url}}&nbsp;
                      <span class="allicon"><i class='fas fa-external-link-alt fa-lg '></i></span>
                    </a>
                  </div>
                </div>
                <div class="row setheadid">
                  <div class="col-2 text-center">
                    <mat-icon class="setcopy">person</mat-icon>
                  </div>
                  <div class="col-10">
                    <p class="setpera">{{x?.username}}
                      <span (click)="copyToClip(x?.username)" class="all"><mat-icon
                          class="setcopy">file_copy</mat-icon></span>
                    </p>
                  </div>
                </div>
                <div class="row setheadid" *ngIf="hidehoure(x.createdAt)===true">
                  <div class="col-2 text-center">
                    <mat-icon class="setcopy">lock</mat-icon>
                  </div>
                  <div class="col-10">
                    <p class="setpera">{{x?.password}}
                      <span (click)="copyToClip(x?.password)" class="all"><mat-icon
                          class="setcopy">file_copy</mat-icon></span>
                    </p>
                  </div>
                </div>
                <div class="row setfoot">
                  <div class="col-6 text-center">
                    <span class="setdbtn" (click)="gowithdraw(x,'deposit')">Deposit</span>
                  </div>
                  <div class="col-6 text-center">
                    <span class="setwbtn" (click)="gowithdraw(x,'withdraw')">Withdraw</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="setsuport">
      <a href="https://wa.me/+919427953875">
        <img src="assets/payment/whatsapp.png" style="width:16%">
      </a>
    </div> -->
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-sidenav mode="side" #sidenavLeft closed position="start" (click)="sidenavLeft.toggle()" class="right-panel">
  <!-- <div class="sidebar-first-heading">
        <span><mat-icon>close</mat-icon></span>
        <button mat-stroked-button color="warn" (click)="logoutUser()">Logout <mat-icon>logout</mat-icon></button>
    </div> -->

 <!----sidemei new start---->
 <div class="sidemenu-wrap-new">
  <div class="side-menu-logo">
    <div class="logo-info-wrap">
      <img src="../../assets/images/logo.svg" class="logo-img">
      <h3 *ngIf="user"><img src="../../assets/images/India.svg"> {{user?.details?.mobile}} </h3>
      <h3 *ngIf="!user"><img src="../../assets/images/India.svg"> +91</h3>
      <!-- <h3 *ngIf="mobile"><img src="../../assets/images/India.svg"> +91 9988862261 </h3> -->
      <hr class="horizontal-line">
      <div class="wallet-info">
        <h4>Wallet Balance</h4>
        <p class="wallet-bal-cont">{{userwalletBalance| number : '0.0-2'}}</p>
      </div>
    </div>
    <a class="close-menu" href="#">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </a>
    <a class="logout-top-icon"  (click)="logoutUser()">
      <mat-icon>logout</mat-icon>
    </a>
  </div>
  <div class="side-nav-list">
    <div class="sidenavitem">
      <a routerLink="/profile">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-profile-icon.svg">
        <span>Profile</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a routerLink="/wallet-withdraw-details">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-payment-details.svg">
        <span>Withdrawal Details</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a routerLink="/wallet-idx">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-create-id.svg">
        <span>Create ID</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a href="#">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-rewards-icon.svg">
        <span>Bonus</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a href="#">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-rewards-icon.svg">
        <span class="reward">Bonus <div class="actionicon"><mat-icon> insert_drive_file</mat-icon><mat-icon>info</mat-icon></div></span>
      </div>
    </a>
    </div>
    <div class="bonus-details">
      <div class="bonus-info">
        <p>Total Bonus: 0</p>
        <div class="claim-btn">
          <p>Claimable Bonus: 0</p>
          <button aria-disabled="true" class="button-claim"> Claim </button>
        </div>
        <p class="n-msg"> Min. Claimable Coins: 0 </p>
      </div>
    </div>
    <div class="bigspace"></div>
    <div class="sidenavitem">
      <a routerLink="/terms-condition">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-terms-icon.svg">
        <span>Terms</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a routerLink="/wallet-offer">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-offer-icon.svg">
        <span>Offers</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a routerLink="/wallet-notifications">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-notification-icon.svg">
        <span>Notifications</span>
      </div>
    </a>
    </div>
    <div class="sidenavitem">
      <a href="#">
      <div class="item-inner">
        <img src="../../assets/images/side-nav-help-icon.svg">
        <span>Help</span>
      </div>
    </a>
    </div>

    <div class="appdownlaodbutton">
      <button class="downlaodapp"><mat-icon>android</mat-icon> Download App</button>
      <button class="logoutbtn"  (click)="logoutUser()">Logout</button>
    </div>
  </div>
</div>
<!----sidemei new end-->



  <div class="row user-profile align-items-center" hidden>
    <div class="col-xs-3">
      <img src="assets/images/user-circle.svg" />
    </div>
    <div class="col-xs-3 username">
      {{ user?.details?.username }}
      <div class="last-login">
      </div>
    </div>
    <div class="col-xs-3">
      <button mat-stroked-button color="warn" (click)="logoutUser()"> <mat-icon>logout</mat-icon></button>
    </div>
  </div>

  <div class="row" style="margin: 5px 10px" hidden>
    <div class="winning-card">
      <a style="color: #ffffff">
        <img src="assets/icons/account_balance.png" />
        <div>Balance</div>
        <h5>
          <b>{{ walletBalance?.balance | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <!-- <div class="winning-card">
      <a style="color: #ffffff">
        <i class="material-icons">casino</i>
        <img src="assets/icons/events.png">
        <div>Casino</div>
        <h5>
          <b>{{ casinoBal | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div> -->

    <div class="exposure-card">
      <img src="assets/icons/exposure.png" />
      <div>Exposure</div>
      <h5>
        <b>{{ walletBalance?.exposure | number : "0.0-2" }}</b>
      </h5>
    </div>
  </div>

  <div class="report-menu" hidden>
    <h4></h4>
    <ul class="menu-list">
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet']"><img src="assets/images/icon/home.png" /> Home</a>
      </li>
      <li>
        <a class="dropdown-item" (click)="deposit()"><img src="assets/images/wallet.svg" /> Deposit</a>
      </li>
      <li>
        <a class="dropdown-item" (click)="withdraw()"><img src="assets/images/wallet.svg" /> Withdraw</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-passbook']"><img src="assets/images/wallet.svg" /> Passbook</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-idx']"><img src="assets/images/icon/change_stack.png" />My
          Ids</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-notifications']"><img
            src="assets/images/icon/report.svg" />Notifications</a>
      </li>
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/wallet-offer']"><img src="assets/images/wallet.svg" /> Offers</a>
      </li> -->
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-withdraw-details']"><img
            src="assets/images/icon/user.svg" />Withdraw Details</a>
      </li>
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/referAcc']"
          ><img src="assets/images/wallet.svg" /> Refer Account</a
        >
      </li> -->
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/profile']"
          ><img src="assets/images/icon/user.svg" /> Profile</a
        >
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/terms-condition']"
          ><img src="assets/images/icon/report.svg" /> Terms & Conditions</a
        >
      </li> -->
    </ul>
  </div>
</mat-sidenav>