import { Component, OnInit, TemplateRef } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-wallet-passbook',
  templateUrl: './wallet-passbook.component.html',
  styleUrls: ['./wallet-passbook.component.scss']
})
export class WalletPassbookComponent implements OnInit {
  user: any;
  transactions: any;
  token: any;
  detaile: any;
  pasbooketails: any;
  logo: any;
  reg_symbol: boolean = true;
  modalRef: BsModalRef;
  modalRefcancel: BsModalRef;
  cancelwithdrawForm: FormGroup;
  cancelmessage: any;
  hide: boolean = false;
  // cancelwithdrawForm:any;
  userDetails:any;
  ledgerList:any;
  days:number=1;
  referLog:any;
  betList:any;
  constructor(
    private usersService: UsersService,
    private router: Router,
    private _location: Location,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private socket: Socket
  ) { 
    this.token = sessionStorage.getItem("token");
  }

  async ngOnInit() { 
    const data = await JSON.parse(sessionStorage.getItem('userDetails'));
    this.user = data.details;
    // console.log(this.user);
    this.getBetHistry();
    this.gettransactions();
    this.createFrom();
    this.storeData();
  }
  gettransactions() {
    this.usersService.getdata("transactions/" + this.token).subscribe((response: any) => {
      // console.log(response);
      this.transactions = response.data;
    })
  }
  // hide(data) {
  //   if(data==true){
  //     this.status = false;
  //   }else{
  //     this.status = true;
  //   }
  // }

  createFrom() {
    this.cancelwithdrawForm = this.fb.group({
      reason: ['', [Validators.required, Validators.minLength(12)]],
    })
  }

  openmodal(passbookdetaile: TemplateRef<any>, detailes) {
    this.hide=false;
    this.detaile = detailes;
    // console.log(this.detaile);
    var data = { id: detailes._id }
    if (this.token) {
      this.usersService.postdata("gettransactionById/" + this.token, data).subscribe((response: any) => {
        // console.log(response);
        this.pasbooketails = response.data;
      })
    }
    this.modalRef = this.modalService.show(
      passbookdetaile,
      Object.assign({}, { class: 'passbookdetaile modal-lg' })
    );
  }

  cancelwithdraw() {
    if (this.cancelwithdrawForm.value.reason != '') {
      var data = {
        id: this.pasbooketails._id,
        remarks: this.cancelwithdrawForm.value.reason,
      }
      console.log(data);
      this.usersService.putdata("cancelWithdrawl/" + this.token, data).subscribe((response: any) => {
        // console.log(response);
        this.cancelmessage = response;
        if (response) {
          // document.getElementById("openModalButton").click();
          this.toastr.success(response.message);
        }
      });
    }

  }


  cancel(success: TemplateRef<any>, detailes) {
    this.modalRefcancel = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  backClicked() {
    // console.log('test')
    // this._location.back();
    this.router.navigate(['wallet']);
  }

  gonotification() {
    this.router.navigate(['wallet-notifications']);
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async storeData()
  {
    this.userDetails=await this.getDetials();
    // this.getLedgerSoc(this.days);
  }

    getLedgerSoc(value:number)
    {

      const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username: this.userDetails.details.username,
        action: {$in: ['BALANCE', 'AMOUNT', , 'COMMISION']},
        deleted: false,
        time: {
          $gte: new Date(new Date().getTime() - (value * 24 * 60 * 60 * 1000)),
        },
      },
      sort: {time: -1},
    };
  
    this.socket.emit('get-logs', userdata);

    this.socket.on('get-logs-success', (function(data:any){
      if(data){ 
        // console.warn(data);
        
        // this.toastr.success(data.message, 'Success!');
        this.ledgerList=data;
        this.removeListner();
      }
          
     }).bind(this));
    
  }

  async getBetHistry()
  {
    this.userDetails=await this.getDetials();
    const userdata = {
      user: {
         _id: this.userDetails._id,
         key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
         username: this.userDetails.details.username,
         status:"MATCHED",
         deleted: false,
         result:{$ne:'ACTIVE'}, 
         placedTime: {$gte: (new Date((new Date()).getTime() - (30*24*60*60*1000)))}
      },
      sort: {placedTime: -1 },
    };
  
    this.socket.emit('get-bets', userdata);

    this.socket.on('get-bets-success', (function(data:any){
      if(data){ 
        this.betList=data;
        // console.log(data);
        
      }
          
     }).bind(this));
    
  }

  onValChange(value)
  {
    this.getLedgerSoc(value);
  }

  removeListner()
  {
     this.socket.removeListener('get-logs-success');
  }

  refer_log(ledgerBook: TemplateRef<any>,x:any)
  {
    this.referLog=x;
    this.modalRef = this.modalService.show(
      ledgerBook,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
  }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
