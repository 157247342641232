import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UsersService } from "../services/users.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  varifyOtpForm: FormGroup;
  registerButtonDisable = false;
  otpButtonDisable = false;
  submitted = false;
  private clickTimeout: any = null;
  public showPassword: boolean;
  a: any;
  logo: string;
  step: number = 1;
  url_youtube:any;
  url_insta:any;
  url_telegram:any;
  username: any;
  constructor(
    private router: Router,
    public httpClient: UsersService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.changeIcon();
    this.createFrom();
  }

  async findHostName() {
    return window.location.hostname;
  }

  async changeIcon() {
    const hostname = await this.findHostName();
    const splithostname = hostname.split(".");
    this.logo = splithostname[0];
    this.setManagerId();
  }

  createFrom() {
    this.registerForm = this.fb.group({
      type: "Subadmin",
      typeId: "",
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      username: [""],
      name: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(4)]],
      referal_code: "",
    });

    this.varifyOtpForm = this.fb.group({
      type: "Subadmin",
      typeId: "",
      phone: ["", [Validators.required]],
      otp: ["", [Validators.required]],
    });
  }

  setManagerId() {
 
    if (this.logo === "clubprt") {
      this.registerForm.patchValue({ typeId: "62b013cd6d70f31108551e35" });
      this.varifyOtpForm.patchValue({ typeId: "62b013cd6d70f31108551e35" });
    this.url_youtube="";
    this.url_insta="https://instagram.com/prtglobal?igshid=MmIzYWVlNDQ5Yg==";
    this.url_telegram="https://t.me/clubprt";
    } else if (this.logo === "ferrariclubb") {
      this.registerForm.patchValue({ typeId: "62a9c86c09efe50c87d82ac8" });
      this.varifyOtpForm.patchValue({ typeId: "62a9c86c09efe50c87d82ac8" });
    } else if (this.logo === "clubaob") {
      this.registerForm.patchValue({ typeId: "62ab28172d4cdfa344c03770" });
      this.varifyOtpForm.patchValue({ typeId: "62ab28172d4cdfa344c03770" });
    } else if (this.logo === "dlexch") {
      this.registerForm.patchValue({ typeId: "62b9951ba94a631790af24f4" });
      this.varifyOtpForm.patchValue({ typeId: "62b9951ba94a631790af24f4" });
    } else if (this.logo === "osgexch365") {
      this.registerForm.patchValue({ typeId: "655b609be2e394f65f6a26e4" });
      this.varifyOtpForm.patchValue({ typeId: "655b609be2e394f65f6a26e4" });
    } else if (this.logo === "fairbets247") {
      this.registerForm.patchValue({ typeId: "631195e7d84105a6457fd88e" });
      this.varifyOtpForm.patchValue({ typeId: "631195e7d84105a6457fd88e" });
    } else if (
      this.logo === "paisaexch" ||
      this.logo === "clubosg" ||
      this.logo === "dubaiclub247"
    ) {
      if(this.logo === "clubosg"){
        this.url_youtube="https://youtube.com/@clubosg?si=seaAPXhS_KifDCyu";
        this.url_insta="https://instagram.com/clubosg_official?igshid=OGQ5ZDc2ODk2ZA==";
        this.url_telegram="https://t.me/osgclub";
      }
     
      this.registerForm.patchValue({ typeId: "6298db693453531745fc9c8f" });
      this.varifyOtpForm.patchValue({ typeId: "6298db693453531745fc9c8f" });
    } else if (this.logo === "clublucky7") {
      this.registerForm.patchValue({ typeId: "64631860977b92c664eeb2c1" });
      this.varifyOtpForm.patchValue({ typeId: "64631860977b92c664eeb2c1" });
    } else if (this.logo === "boomboombook") {
      this.registerForm.patchValue({ typeId: "649adb3ac7aa589207a040a8" });
      this.varifyOtpForm.patchValue({ typeId: "649adb3ac7aa589207a040a8" });
      // this.registerForm.patchValue({ typeId: "649a624e0228b75fab6881d4" });
      // this.varifyOtpForm.patchValue({ typeId: "649a624e0228b75fab6881d4" });
    } else {
      this.registerForm.patchValue({ typeId: "6298db693453531745fc9c8f" });
      this.varifyOtpForm.patchValue({ typeId: "6298db693453531745fc9c8f" });
    }
  }

  async onRegisterClick() {
    this.registerButtonDisable = true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
    } else {
      this.setClickTimeout(() => this.handleSingleLoginClick());
    }
  }

  async onVerifyOtpClick() {
    this.otpButtonDisable = true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
    } else {
      this.setClickTimeout(() => this.handleSingleVerifyClick());
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  remveSpace(event) {
    this.username = event.target.value.replace(/\s+/g, "");
    this.registerForm.value.username = event.target.value.replace(/\s+/g, "");
  }

  public handleSingleLoginClick() {
    //The actual action that should be performed on click
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerButtonDisable = false;
      this.toastr.error("field is invalid !");
      return;
    }

    if (this.logo === "clublucky7") {
      if (this.registerForm.value.username == "") {
        this.registerButtonDisable = false;
        this.toastr.error("username is required !");
        return;
      }

      this.registerForm.value.username = this.username.toUpperCase();
    }

    const a = "+91" + this.registerForm.value.phone;
    this.registerForm.value.phone = a;
    this.varifyOtpForm.patchValue({ phone: this.registerForm.value.phone });

    const registerData = this.registerForm.value;

    this.httpClient
      .Post("https://acepunt.kushubmedia.com/user/register/", registerData)
      .subscribe((response: any) => {
        console.log(response);
        
        if (response.success || response.message === "User already registered") {
          // this.toastr.success(response.message, "Success!");
          this.step = 2;
        } else {
          this.toastr.error(response.message, "Error!");
          this.submitted = false;
          this.registerButtonDisable = false;
        }
      });
  }
  public resendhandleSingleLoginClick() {
    //The actual action that should be performed on click
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerButtonDisable = false;
      this.toastr.error("field is invalid !");
      return;
    }

    if (this.logo === "clublucky7") {
      if (this.registerForm.value.username == "") {
        this.registerButtonDisable = false;
        this.toastr.error("username is required !");
        return;
      }

      this.registerForm.value.username = this.username.toUpperCase();
    }

    const a = this.registerForm.value.phone;
    this.registerForm.value.phone = a;
    this.varifyOtpForm.patchValue({ phone: this.registerForm.value.phone });

    const registerData = this.registerForm.value;
      console.log(registerData);
      
    this.httpClient
      .Post("https://acepunt.kushubmedia.com/user/resendOtp/", registerData)
      .subscribe((response: any) => {
        console.log(response);
        
        if (response.success || response.message === "User already registered") {
          // this.toastr.success(response.message, "Success!");
          this.step = 2;
        } else {
          this.toastr.error(response.message, "Error!");
          this.submitted = false;
          this.registerButtonDisable = false;
        }
      });
  }

  public handleSingleVerifyClick() {
    //The actual action that should be performed on click
    this.submitted = true;
    if (this.varifyOtpForm.invalid) {
      this.otpButtonDisable = false;
      return;
    }

    const otpData = this.varifyOtpForm.value;
    this.httpClient
      .Post("https://acepunt.kushubmedia.com/user/verifyOtp/", otpData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success(response.message, "Success!");
          this.redirectToLogin();
        } else {
          this.toastr.error(response.message, "Error!");
          this.submitted = false;
          this.otpButtonDisable = false;
        }
      });
  }

  public setClickTimeout(callback: any) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }

  redirectToLogin() {
    this.router.navigate(["login"]);
  }
}
