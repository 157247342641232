<!-- <div class="close-btn" (click)="closeSheet($event)">CLOSE <mat-icon>close</mat-icon></div>  -->



<div [ngClass]="arrayObj.is_back==1 ? 'back' : 'lay'">
    <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="bet-slip-header">
        <span class="bet-slip-title">Bet Slip</span>
        <div class="close-btn" style="color:#fff" (click)="closeSheet($event)"> <mat-icon>close</mat-icon></div>
    </div> -->
    <mat-divider>
        <div *ngIf="ring == true"><audio autoplay>
                <source src="assets/ring/Iphone 12 Pro Max - Message Tone.mp3" type="audio/mpeg">
            </audio></div>
    </mat-divider>

    <div class="betslip-wrapper-custom" *ngFor="let back of this.matchModel.tempArray;let index=index">
        <div class="betslip-wrapper">
            <div class="partial-betslip">
                <div style="position: relative;" id="" [ngClass]="arrayObj.is_back==1 ? 'back' : 'lay'"
                    class="inline-betslip">
                    <div class="betslip-input">
                        <div class="betslip-head">
                            <p class="pull-left" style="flex: 0.5;" *ngIf="arrayObj.is_back==1"> BACK </p>
                            <p class="pull-left" style="flex: 0.5;" *ngIf="arrayObj.is_back!=1"> Lay </p>

                            <div class="inputBtns">
                                <span class="betslip-info">Profit: <i class="badge badge-success">{{back.stake <= '0' ? '0' : (back.is_back==0
                                    ? back.stake :((back.odds*back.stake)-back.stake)=='NaN' ?0:
                                    ((back.odds*back.stake)-back.stake).toFixed(2))}}</i>
                                </span>&nbsp;&nbsp;
                                <span class="betslip-info">Loss: <i class="badge badge-danger">{{back.stake <= '0' ? '0' :(back.is_back==0 ?
                                    ((back.odds*back.stake)-back.stake)=='NaN' ?0: ((back.odds*back.stake)-back.stake).toFixed(2) :
                                    back.stake)}}</i>
                                </span>
                            </div>
                            <div class="keep-alive">
                                <label for="keepAlive">
                                    <input type="checkbox" id="keepAlive" class="filled-in">
                                    <span class="form-check-label" style="color: black !important;"> Keep Alive
                                    </span>
                                </label>
                            </div>

                        </div>
                        <div class="input-btn-wrapper">
                            <div class="input-box-wrapper">
                                <div class="inputOdds stake-odd-heading">
                                    <span>Odds</span>
                                    <span>Stake</span>
                                </div>
                                <div class="inputOdds">
                                    <div class="input-wrapper mr-10" style="padding-right: 5px; padding-left: 0px;">

                                        <input *ngIf="back?.isManual=='0'" [(ngModel)]="back.odds"
                                            [ngModelOptions]="{standalone: true}" value="{{back?.odds}}"
                                            id="ShowBetPrice" inputmode="numeric" pattern="[0-9]*" type="number"
                                            placeholder="odds" name="odds" min="1.01" max="1000" class="" readonly>

                                    </div>


                                    <div class="input-wrapper" style="padding-left: 5px;">
                                        <button class="btn-left br-left disable-dbl-tap-zoom" fdprocessedid="mhuo4g"
                                            (click)="back.stake = back.stake - 100;calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">
                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                        </button>

                                        <input type="number" min="0" id="stackVal" (focus)="setbtn(index)"
                                            (input)="calculateP_lOnStackOnInput(back?.stake,back?.stake,back?.is_back,back);"
                                            pattern="[0-9]*" step="1" id="stakeValue" placeholder="0" class=""
                                            [(ngModel)]="(back.stake < 0) ? '0' :back.stake "
                                            [ngModelOptions]="{standalone: true}">
                                        <button class="btn-right br-right disable-dbl-tap-zoom" fdprocessedid="4z0r6p"
                                            (click)="back.stake = back.stake + 100;calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="inputBtns"  *ngIf="!status">
                                <button class="mr-10" style="margin-right: 5px;" (click)="closeSheet($event)">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i> Cancel </button>
                                <button class="place" style="margin-left: 5px; ;margin-right: 0px;"
                                    [disabled]="betloder" (click)="saveBet(this.matchModel.tempArray)"
                                    *ngIf="arrayObj.is_fancy == 0">
                                    <i class="fas fa-check-circle" aria-hidden="true"></i> Submit </button>
                                <button class="place" style="margin-left: 5px; ;margin-right: 0px;"
                                    [disabled]="betloder" (click)="saveFancyBet(this.matchModel.tempArray)"
                                    *ngIf="arrayObj.is_fancy == 1">
                                    <i class="fas fa-check-circle" aria-hidden="true"></i> Submit </button>
                            </div>
                            <div class="inputBtns"  *ngIf="status">
                                <button class="mr-10" style="margin-right: 5px;" (click)="closeSheet($event)">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i> Cancel </button>
                                <button class="place" style="margin-left: 5px; ;margin-right: 0px;" (click)="updatestake()">
                                    <i class="fas fa-check-circle" aria-hidden="true"></i> Update Stake </button>

                            </div>

                            <div class="stakebtns-custom">
                                <!-- <button *ngFor="let stake of userMatchStack| keyvalue;let i= index"
                                    (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">{{stake.value}}</button>
                            -->
                                    <div #widgetsContent class="scrollmenu" *ngIf="!status">
                                        <div class="tab-btn-card" *ngFor="let stake of editstake;let i= index"
                                            (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake,back.is_back,back);">
                                            {{stake}}
                                        </div>
                    
                                        <div class="tab-editbtn-card"
                                            (click)="status=!status">
                                            Edit
                                        </div>
                                    </div>
                                    <div #widgetsContent class="scrollmenu" *ngIf="status">
                                        <input class="tab-btn-card" style="border: 1px solid;" value="{{stake}}" *ngFor="let stake of editstake;let i= index"
                                            (change)="onModelChange($event ,i)">
                                    </div>
                           
                                </div>


                            <div class="teampl-table-wrap">
                                <table class="table ">
                                    <tr *ngFor="let r of arrayObj?.matchOddsData?.marketBook?.runners;let i=index">
                                        <td>{{searchRunner(arrayObj.matchOddsData?.runners,r?.selectionId)}} </td>
                                        <td> <span
                                                [ngClass]="calProLoss(arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData) >0  ? 'profit_v' : 'loss_v'">
                                                {{calProLoss(arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData)|
                                                number : '0.0-2'}}</span> </td>
                                        <td *ngIf="back.stake>0"> <span
                                                [ngClass]="calProLoss_pre(back.stake,arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData) >0  ? 'profit_v' : 'loss_v'">{{calProLoss_pre(back.stake,arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData)|
                                                number : '0.0-2'}}</span> </td>
                                    </tr>
                                </table>
                            </div>
                    
                    
                    
                            <!-- <div class="form-list" *ngIf="back.is_session_fancy=='N'" fxLayout="row" fxLayoutAlign="space-between center"
                                fxLayoutGap="10px">
                    
                                <div class="stack_input_field">
                                    <label class="form-label">Profit</label>
                                    <span id="profitData" class="profit" style="color: green;">{{back.stake <= '0' ? '0' : (back.is_back==0
                                            ? back.stake :((back.odds*back.stake)-back.stake)=='NaN' ?0:
                                            ((back.odds*back.stake)-back.stake).toFixed(2))}}</span>
                                </div>
                    
                    
                                <div class="stack_input_field">
                                    <label class="form-label">Loss</label>
                                    <span id="profitData" class="profit" style="color: red;">-{{back.stake <= '0' ? '0' :(back.is_back==0 ?
                                            ((back.odds*back.stake)-back.stake)=='NaN' ?0: ((back.odds*back.stake)-back.stake).toFixed(2) :
                                            back.stake)}}</span>
                                </div>
                            </div>
                    
                    
                            <div class="form-list" *ngIf="back.is_session_fancy=='Y'" fxLayout="row" fxLayoutAlign="space-between center"
                                fxLayoutGap="10px">
                    
                                <div class="stack_input_field">
                                    <label class="form-label">Profit</label>
                                    <span id="profitData" class="profit" style="color: green;">{{back.is_back ==
                                        0 ? back.stake : ((back.size * back.stake)/100).toFixed(2)}}</span>
                                </div>
                    
                    
                                <div class="stack_input_field">
                                    <label class="form-label">Loss</label>
                                    <span id="profitData" class="profit" style="color: red;">{{back.is_back == 0
                                        ? ((back.size * back.stake)/100).toFixed(2) : back.stake}}</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<!-- <div [ngClass]="arrayObj.is_back==1 ? 'back' : 'lay'">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="bet-slip-header">
        <span class="bet-slip-title">Bet Slip</span>
        <div class="close-btn" style="color:#fff" (click)="closeSheet($event)"> <mat-icon>close</mat-icon></div>
    </div>
    <mat-divider>
        <div *ngIf="ring == true"><audio autoplay>
                <source src="assets/ring/Iphone 12 Pro Max - Message Tone.mp3" type="audio/mpeg">
            </audio></div>
    </mat-divider>
    <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start" class="bet-slip-body"
        *ngFor="let back of this.matchModel.tempArray;let index=index">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">

            <div class="form-list">
                <span fxLayoutAlign="start" class="titleformlay">
                    <mat-icon>arrow_right_alt</mat-icon>
                    <label class="form-label" *ngIf="back?.is_session_fancy=='N'"> <i class="fa fa-arrow-right"></i>
                        {{back?.is_back==1 ? 'Back' :'Lay'}}</label>
                    <label class="form-label" *ngIf="back?.is_session_fancy=='Y'"> <i class="fa fa-arrow-right"></i>
                        {{back?.is_back==1 ? 'Back' :'Lay'}}</label>
                </span>
                <div class="input-group">
                    <input type="text" *ngIf="back?.isManual=='0'" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0"
                        step="0.01" [(ngModel)]="back.odds" id="ShowBetPrice"
                        class="calProfitLoss odds-input form-control CommanBtn ng-untouched ng-pristine ng-valid"
                        [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                        value="{{back?.odds}}" readonly>
                </div>
            </div>
            <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center center">
                <span fxLayoutAlign="start" class="titleformlay">
                    <mat-icon>stacked_line_chart</mat-icon>STACK
                </span>
                <div class="form-list" fxLayoutGap="10px">
                    <div class="input-group" fxLayout="row" fxLayoutGap="3px">
                        <span class="input-group-text"
                            (click)="back.stake = back.stake - 100;calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">
                            <mat-icon>remove</mat-icon>
                        </span>
                        <input type="number" min="0" id="stackVal" (focus)="setbtn(index)"
                            (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                            pattern="[0-9]*" step="1" id="stakeValue" placeholder="0"
                            class="form-control calProfitLoss stake-input f0orm-control CommanBtn ng-untouched ng-pristine ng-valid"
                            [(ngModel)]="(back.stake < 0) ? '0' :back.stake " [ngModelOptions]="{standalone: true}">
                        <span class="input-group-text"
                            (click)="back.stake = back.stake + 100;calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);">
                            <mat-icon>add</mat-icon>
                        </span>
                    </div>
                </div>

            </div>
        </div>
        <div class="betslip-button text-center">
            <div style="display: flex;width: 100%; vertical-align: middle;">

                <div #widgetsContent class="scrollmenu">
                    <div class="tab-btn-card" *ngFor="let stake of userMatchStack| keyvalue;let i= index"
                        (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                        {{stake.value}}
                    </div>

                </div>

            </div>
        </div>

        <div class="placebetbtns">
            <button type="button" class="btn btn-outline-primary btn-primary btn-block rounded" [disabled]="betloder"
                (click)="saveBet(this.matchModel.tempArray)" *ngIf="arrayObj.is_fancy == 0">Place Bet</button>

            <button type="button" class="btn btn-outline-primary btn-primary btn-block rounded" [disabled]="betloder"
                (click)="saveFancyBet(this.matchModel.tempArray)" *ngIf="arrayObj.is_fancy == 1">Place Bet</button>
        </div>

        <div class="teampl-table-wrap">
            <table class="table ">
                <tr *ngFor="let r of arrayObj?.matchOddsData?.marketBook?.runners;let i=index">
                    <td>{{searchRunner(arrayObj.matchOddsData?.runners,r?.selectionId)}} </td>
                    <td> <span
                            [ngClass]="calProLoss(arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData) >0  ? 'profit_v' : 'loss_v'">
                            {{calProLoss(arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData)|
                            number : '0.0-2'}}</span> </td>
                    <td *ngIf="back.stake>0"> <span
                            [ngClass]="calProLoss_pre(back.stake,arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData) >0  ? 'profit_v' : 'loss_v'">{{calProLoss_pre(back.stake,arrayObj?.matchOddsData?.runners,arrayObj?.matchOddsData?.marketBook,i,arrayObj?.matchOddsData)|
                            number : '0.0-2'}}</span> </td>
                </tr>
            </table>
        </div>



        <div class="form-list" *ngIf="back.is_session_fancy=='N'" fxLayout="row" fxLayoutAlign="space-between center"
            fxLayoutGap="10px">

            <div class="stack_input_field">
                <label class="form-label">Profit</label>
                <span id="profitData" class="profit" style="color: green;">{{back.stake <= '0' ? '0' : (back.is_back==0
                        ? back.stake :((back.odds*back.stake)-back.stake)=='NaN' ?0:
                        ((back.odds*back.stake)-back.stake).toFixed(2))}}</span>
            </div>


            <div class="stack_input_field">
                <label class="form-label">Loss</label>
                <span id="profitData" class="profit" style="color: red;">-{{back.stake <= '0' ? '0' :(back.is_back==0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN' ?0: ((back.odds*back.stake)-back.stake).toFixed(2) :
                        back.stake)}}</span>
            </div>
        </div>


        <div class="form-list" *ngIf="back.is_session_fancy=='Y'" fxLayout="row" fxLayoutAlign="space-between center"
            fxLayoutGap="10px">

            <div class="stack_input_field">
                <label class="form-label">Profit</label>
                <span id="profitData" class="profit" style="color: green;">{{back.is_back ==
                    0 ? back.stake : ((back.size * back.stake)/100).toFixed(2)}}</span>
            </div>


            <div class="stack_input_field">
                <label class="form-label">Loss</label>
                <span id="profitData" class="profit" style="color: red;">{{back.is_back == 0
                    ? ((back.size * back.stake)/100).toFixed(2) : back.stake}}</span>
            </div>
        </div>


        <div class="cancelclear">
            <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">

                <div class="col p-0">
                    <div class="accept-any" *ngIf="arrayObj.is_fancy == 0 && arrayObj.MatchName=='Match Odds'">
                        <label><input type="checkbox" (click)="acceptAnyBet($event)"> Accept Any Odds</label>
                    </div>
                </div>
                <button type="button" class="btn btn-outline-danger btn-sm rounded"
                    (click)="this.matchModel.tempArray=[];clearAll();closeSheet();">Cancel</button>

                <button type="button" class="btn btn-outline-success btn-sm rounded"
                    (click)="back.stake=0;back.p_l=0;">Clear</button>

            </div>
        </div>


    </div>
</div> -->