import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WalletCreateIdxComponent } from '../wallet-create-idx/wallet-create-idx.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from "ngx-toastr";
import { Socket } from "ngx-socket-io";

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
  iframUrl: any;
  walletuser: any;
  user: any;
  support_nO: any;
  logintokenscreen: any;
  transactions: any;
  createid: any;
  myid: any;
  detaile: any;
  offers: any;
  IdDetail: any;
  modalRef: BsModalRef;
  walletBalance: any;
  offerstatus: any;
  userwalletBalance: any;
  userwalletexposer: any;
  hidehour: any;
  userDetail: any;
  banner_img: any;
  cricketData: any;
  soccerData: any;
  tennisData: any;
  virtualCricketData: any;
  loginButtnStatus: boolean = false;
  constructor(public sanitizer: DomSanitizer, private socket: Socket, private clipboard: Clipboard, private router: Router, private modalService: BsModalService, private usersService: UsersService, private toastr: ToastrService) { }

  async ngOnInit(): Promise<void> {

    if (sessionStorage.getItem("loginStatus") === "true") {
      this.loginButtnStatus = true;
      const usrDetails = await this.getDetials();
      this.user = usrDetails;
      // console.log(this.user);
      this.getusertoken();
      this.getUserBalance();
      this.Inplay();
      // console.log(this.user);
      const data = {
        user: {
          _id: this.user._id,
          key: this.user.key,
          details: {
            username: this.user.details.username,
            role: this.user.details.role,
            status: this.user.details.status,
          }
        },
  
      };
      // console.log(data);
      this.socket.emit("get-deposit-status", data);
      this.socket.on(
        "get-deposit-status-success", function (data: any) {
          var offrstatus = data.count;
          // console.log(data);
          if (offrstatus == 0) {
            var postdata = { filter: { manager: this.user?.details?.manager } }
            this.usersService.post_api("get-offer/", postdata).subscribe((response: any) => {
              // console.log(response);
              this.offers = response.data;
              for (let i = 0; i < this.offers.length; i++) {
                if (this.offers[i].status == 2) {
                  // console.log(this.offers[i]);
                  this.offerstatus = this.offers[i];
                }
              }
            })
          } else {
            var postdata = { filter: { manager: this.user?.details?.manager } }
            this.usersService.post_api("get-offer/", postdata).subscribe((response: any) => {
              // console.log(response);
              this.offers = response.data;
              for (let i = 0; i < 1; i++) {
                if (this.offers[i]?.status == 1) {
                  // console.log(this.offers[i]);
                  this.offerstatus = this.offers[i];
                }
              }
            })
          }
        }.bind(this)
      );
    } else {
   this.homeFreeSoc();
    }
    this.getBanner();

  }

  async homeFreeSoc() {
    let data = {
      filter: {
        managers: "OSGCLUB",
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
      },
      sort: { openDate: 1 },
    };

    this.socket.emit("get-free-home-markets", data);

    this.socket.on(
      "get-freehomemarkets-success",
      function (data: any) {
        this.dataLength = data.length;
        if (data) {
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          this.dataLength = data[0].length || data[1].length || data[2].length;
        }
      }.bind(this)
    );
  }


  getBanner() {
    this.usersService.Get("get-banner/").subscribe((response: any) => {
      console.log(
        "🚀 ~ file: dashboard.component.ts:256 ~ DashboardComponent ~ this.usersService.Get ~ response:",
        response
      );

      this.banner_img = [];
      if (response.error) {
        this.toastr.error(response.message, "Error!");
      } else {
        this.banner_img = response.response;
      }
    });
  }

  async getDetial() {
    try {
      const data = await JSON.parse(sessionStorage.getItem("userDetails"));
      return data;
    } catch (e) {
      return null;
    }
  }

  async Inplay() {
    this.userDetail = await this.getDetial();
    const data = {
      user: {
        _id: this.userDetail._id,
        key: this.userDetail.key,
        token: this.userDetail.verifytoken,
        details: {
          username: this.userDetail.details.username,
          role: this.userDetail.details.role,
          status: this.userDetail.details.status,
        },
      },
      filter: {
        managers: this.userDetail.details.manager,
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
        $or: [
          { "marketBook.inplay": true },
          // { openDate: { $lte: new Date() } },
        ],
      },
      sort: { openDate: 1 },
    };
    console.log(data);

    this.socket.emit("get-inplay-markets", data);

    this.socket.on(
      "get-inplaymarkets-success",
      function (data: any) {
        this.dataLength =
          data[0].length || data[1].length || data[2].length || data[3];

        if (data) {
          console.log(data);
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          this.virtualCricketData = data[3];
        }
      }.bind(this)
    );
  }

  matchDetail(eventId) {
    console.log(eventId);

    this.router.navigate(["match-detail", eventId]);
  }

  getUserBalance() {
    const userdata = {
      user: {
        _id: this.user._id,
        key: this.user.key,
        token: this.user.verifytoken,
        details: {
          username: this.user.details.username,
          role: this.user.details.role,
          status: this.user.details.status,
        },
      }
    };

    this.socket.emit('get-user', userdata);

    this.socket.on('get-user-success', (function (data: any) {
      if (data) {
        this.userwalletBalance = data.balance;
        this.userwalletexposer = data.exposure;
        // console.log( this.userwalletexposer);

      }
    }).bind(this));

  }

  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      this.walletBalance = data.details;
      return data;
    } catch (e) {
      return null;
    }

  }

  async getusertoken() {
    this.usersService.getdata("getUserByToken/" + this.user.details.username).subscribe((response: any) => {
      this.logintokenscreen = response.data.token;
      if (this.logintokenscreen) {

        sessionStorage.setItem('token', this.logintokenscreen);
        //  console.log(response);
        this.getuser();
        this.gettransactions();
        this.getmyid();
        this.getoffers();

      }

    })
  }

  getuser() {

    this.usersService.getdata("getUser/" + this.logintokenscreen).subscribe((response: any) => {
      this.walletuser = response.doc;
      //  console.log(response);
      this.getSupport(this.walletuser?.typeId)
      sessionStorage.setItem('wallet-user', JSON.stringify(this.walletuser));
      this.getcreateids(this.walletuser);
    })
  }

  logoutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
    window.location.replace('login');
  }


  gettransactions() {
    // console.log('array');
    this.usersService.getdata("transactions/" + this.logintokenscreen).subscribe((response: any) => {
      var array = response.data;
      // console.log(array);
      this.transactions = [];
      if (array) {
        array.forEach(data => {
          if (data.status == "Pending") {
            this.transactions.push(data);
          }
          // console.log(this.transactions);
        });
      }

    })
  }

  getoffers() {
    var data = { filter: { manager: this.user?.details?.manager } }
    this.usersService.post_api("get-offer/", data).subscribe((response: any) => {
      // console.log(response);
      this.offers = response.data;
    })
  }

  getSupport(id) {
    var data = { typeId: id }
    this.usersService.post_kapi("getManager/" + this.logintokenscreen, data).subscribe((response: any) => {
      this.support_nO = response.data?.phone;

    })
  }

  getcreateids(user) {
    var data = { type: user?.type, typeId: user?.typeId }
    this.usersService.postdata("getSite/" + this.logintokenscreen, data).subscribe((response: any) => {
      // console.log(response);
      this.createid = response.doc;
    })
  }

  async copyToClip(value: string) {
    this.clipboard.copy(value);
    this.toastr.success("Copied !");

  };

  getmyid() {
    this.usersService.getdata("getMysites/" + this.logintokenscreen).subscribe((response: any) => {
      console.log(response);
      this.myid = response.data;

    })
  }

  hidehoure(date) {
    var enddate = new Date(date);
    enddate.setDate(enddate.getDate() + 1);
    const startdate = new Date();
    if (startdate < enddate) {
      return true;
    } else {
      return false;
    }
  }

  openmodal(passbookdetaile: TemplateRef<any>, detailes) {
    this.detaile = detailes;
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      passbookdetaile,
      Object.assign({}, { class: 'passbookdetaile modal-lg' })
    );
  }

  IdDetaile(iddetaile: TemplateRef<any>, detailes) {
    this.IdDetail = detailes;
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      iddetaile,
      Object.assign({}, { class: 'iddetaile modal-lg' })
    );
  }

  tabClick(tab) {
    console.log(tab);
  }
  moveToSelectedTab(tabName: string) {
    // console.log(tabName);
    for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
      if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
      }
    }
  }
  // async open() {
  //   this.modalRef = await this.modalService.show(
  //     WalletCreateIdxComponent,
  //     Object.assign({}, { class: 'iddetaile modal-lg' })
  //   );
  // }
  // public openModalWithComponent() {
  //   let initialState = { message: 'popup message', title:'popup title'};
  //   let modalConfig = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
  //   this.modalRef = this.modalService.show(ModalCalendlyComponent, 
  //     Object.assign({}, modalConfig, {class: 'modal-sm', initialState
  //     })
  //     );
  // }

  withdraw() {
    // console.log('test');
    this.router.navigate(['wallet-withdraw/' + 'withdraw']);
  }
  deposit() {
    // console.log('test1');

    this.router.navigate(['wallet-deposit/' + 'deposit']);
  }

  gocreateid(id) {
    sessionStorage.setItem('createid', JSON.stringify(id))
    this.router.navigate(['create-id']);
  }
  gowithdraw(id, data) {
    sessionStorage.setItem('details', JSON.stringify(id))
    this.router.navigate(['deposite-withdraw/' + data]);
  }
  goOffer() {
    this.router.navigate(['wallet-offer']);
  }
  Ids() {
    this.router.navigate(['wallet-idx']);
  }

}
