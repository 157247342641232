import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatchDetailComponent } from './match-detail/match-detail.component';
import { MyBetsComponent } from './my-bets/my-bets.component';
import { LedgerComponent } from './ledger/ledger.component';
import { CasinoComponent } from './casino/casino.component';
import { RentalLoginComponent } from './rental-login/rental-login.component';
import { ProfileComponent } from './profile/profile.component';
import { CasinoWalletComponent } from './casino-wallet/casino-wallet.component';
import { ReferAccComponent } from './refer-acc/refer-acc.component';
import { CasinoUrlComponent } from './casino-url/casino-url.component';
import { WalletComponent } from './wallet/wallet.component';
import { WheelSpinnerComponent } from './wheel-spinner/wheel-spinner.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { RegisterComponent } from './register/register.component';
import { LoginWithOtpComponent } from './login-with-otp/login-with-otp.component';
import { RefreshComponent } from './refresh/refresh.component';
import {AuthenticationGuard}  from './shared/guards/authentication.guard';
import { VirtualDetailComponent } from './virtual-detail/virtual-detail.component';
import { PlayerBattleListComponent } from './player-battle-list/player-battle-list.component';
import { PlayerBattleMarketComponent } from './player-battle-market/player-battle-market.component';
import { FantastyGamesComponent } from './fantasty-games/fantasty-games.component';
import { PlayerContestComponent } from './player-contest/player-contest.component';
import { WalletWithdrawComponent } from './wallet-withdraw/wallet-withdraw.component';
import { WalletDepositComponent } from './wallet-deposit/wallet-deposit.component';
import { WalletPaymentMethodComponent } from './wallet-payment-method/wallet-payment-method.component';
import { WalletNotificationsComponent } from './wallet-notifications/wallet-notifications.component';
import { WalletWithdrawDetailComponent } from './wallet-withdraw-detail/wallet-withdraw-detail.component';
import { WalletCreateIdxComponent } from './wallet-create-idx/wallet-create-idx.component';
import { WalletDepositWithdrawComponent } from './wallet-deposit-withdraw/wallet-deposit-withdraw.component';
import { WalletPassbookComponent } from './wallet-passbook/wallet-passbook.component';
import { WalletIdxComponent } from './wallet-idx/wallet-idx.component';
import { WalletOfferComponent } from './wallet-offer/wallet-offer.component';
import { ReferalReportComponent } from './referal-report/referal-report.component';
import { FixedDepositComponent } from './fixed-deposit/fixed-deposit.component';
import { JoinFixedDepositComponent } from './join-fixed-deposit/join-fixed-deposit.component';
import { FixedDepositReportComponent } from './fixed-deposit-report/fixed-deposit-report.component';
import { ReferalHistoryComponent } from './referal-history/referal-history.component';
import { OfferLedgerComponent } from './offer-ledger/offer-ledger.component';

const routes: Routes = [
  {path: '', redirectTo: 'wallet', pathMatch: 'full'},
  {path: 'wallet', component: WalletComponent},
  {path: 'login', component: RentalLoginComponent},
  {path: 'register',component: RegisterComponent},
  {path: 'login-otp', component: LoginWithOtpComponent},
  {path: 'fantasy-games', component: FantastyGamesComponent},
  {path: 'player-contest', component: PlayerContestComponent},
  // iframe_player_contest
  {path: 'player-contest/:token', component: PlayerContestComponent},
  // {path: 'dashboard', component: DashboardComponent},
  {path: 'home', component: DashboardComponent},
  {path: 'player-battle', component: PlayerBattleListComponent},
  // iframe_player_battle
  {path: 'player-battle/:token', component: PlayerBattleListComponent},
  {path: 'payer-battle-market/:id', component: PlayerBattleMarketComponent},
  // {path: 'dashboard/:sportName', canActivate:[AuthenticationGuard], component: DashboardComponent},
  {path: 'dashboard', canActivate:[AuthenticationGuard], component: DashboardComponent},
  {path: 'match-detail/:eventId', canActivate:[AuthenticationGuard], component: MatchDetailComponent},
  {path: 'virtual-detail/:eventId', canActivate:[AuthenticationGuard], component: VirtualDetailComponent},
  {path: 'ledger', canActivate:[AuthenticationGuard], component: LedgerComponent},
  {path: 'profile', canActivate:[AuthenticationGuard], component: ProfileComponent},
  {path: 'terms-condition', component: TermsConditionComponent},
  {path: 'casino-wallet', canActivate:[AuthenticationGuard], component: CasinoWalletComponent},
  {path: 'wallet', canActivate:[AuthenticationGuard], component: WalletComponent},
  {path: 'create-id', canActivate:[AuthenticationGuard], component: WalletCreateIdxComponent},
  {path: 'deposite-withdraw/:type', canActivate:[AuthenticationGuard], component: WalletDepositWithdrawComponent},
  {path: 'wallet-deposit/:type', canActivate:[AuthenticationGuard], component: WalletDepositComponent},
  {path: 'wallet-withdraw/:type', canActivate:[AuthenticationGuard], component: WalletWithdrawComponent},
  {path: 'wallet-offer', canActivate:[AuthenticationGuard], component: WalletOfferComponent},
  {path: 'wallet-idx', canActivate:[AuthenticationGuard], component: WalletIdxComponent},
  {path: 'wallet-passbook', canActivate:[AuthenticationGuard], component: WalletPassbookComponent},
  {path: 'wallet-payment-method', canActivate:[AuthenticationGuard], component: WalletPaymentMethodComponent},
  {path: 'wallet-notifications', canActivate:[AuthenticationGuard], component: WalletNotificationsComponent},
  {path: 'wallet-withdraw-details', canActivate:[AuthenticationGuard], component: WalletWithdrawDetailComponent},
  {path: 'wheel-spinner', canActivate:[AuthenticationGuard], component: WheelSpinnerComponent},
  {path: 'referAcc', canActivate:[AuthenticationGuard], component: ReferAccComponent},
  { path: "referal-report", canActivate:[AuthenticationGuard], component: ReferalReportComponent },
  { path: "referal-history/:id", canActivate:[AuthenticationGuard], component: ReferalHistoryComponent },
  { path: "fixed-deposit", canActivate:[AuthenticationGuard], component: FixedDepositComponent },
  { path: "join-fixed-deposit/:id", canActivate:[AuthenticationGuard], component: JoinFixedDepositComponent },
  { path: "fixed-deposit-report", canActivate:[AuthenticationGuard], component: FixedDepositReportComponent },
  {path: 'my-bet', canActivate:[AuthenticationGuard], component: MyBetsComponent},
  {path: 'casino',canActivate:[AuthenticationGuard], component:CasinoComponent},
  {path: 'casino-url',canActivate:[AuthenticationGuard], component:CasinoUrlComponent},
  {path: 'refresh', component:RefreshComponent},
  { path: 'offer-ledger', canActivate:[AuthenticationGuard], component: OfferLedgerComponent},
  { path: 'error-pageNotFound', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: 'video', loadChildren: () => import('./video/video.module').then(m => m.VideoModule) },
  { path: 'wallet-iframe', loadChildren: () => import('./wallet-iframe/wallet-iframe.module').then(m => m.WalletIframeModule) },
  { path: '**',redirectTo: 'error-pageNotFound'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{enableTracing:false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
