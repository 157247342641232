<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <!-- <app-header></app-header> -->

    <div class="contianermobile">
      <div class="row setmainhead">
        <mat-toolbar class="mat-toolbar-row newtopheading">
          <div class="left-col logblockinner col ">
            <mat-icon routerLink="wallet" class="back">keyboard_arrow_left</mat-icon>
          </div>
          <div class="col titleblock">
            <h2 class="title">Live Casino </h2>
          </div>
          <div class="col text-right contblok">
            <div class="header-right-cont">
              <div class="bal-wrap">
                <div class="w-bal">
                  <p *ngIf="casinoBal">{{casinoBal | number : '1.0-0'}}</p>
                  <p *ngIf="!casinoBal">0</p>
                  <mat-label>Bal</mat-label>
                </div>
                <div class="w-bal">
                  <p></p>
                  <mat-label>Exp</mat-label>
                </div>
              </div>
              <button color="primary"  routerLink="/casino-wallet" aria-label=""
                class="mat-focus-indicator icon-btn bethistory-btn mat-mini-fab mat-button-base mat-primary"
                tabindex="0">
                <span class="mat-button-wrapper">
                  <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                    data-mat-icon-type="font">list_alt </mat-icon>
                </span>
                <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                <span class="mat-button-focus-overlay"></span>
              </button>
            </div>
          </div>
        </mat-toolbar>
      </div>

      <div *ngIf="!res_msg" class="">

        <!-- <div class="userid-marquee">       
            <p class="marquee">This is a Demo ID (to buy Mercedes play with real money)</p>
          </div> -->
        <div class="filter-search">
          <div class="select-wrap">
            <label>Game Provider</label>
            <select>
              <option>All</option>
              <!-- <option value=""> Ezugi (68) </option>
                <option value=""> Evolution Gaming (286) </option>
                <option value=""> Spribe (1) </option> -->
            </select>
          </div>
          <div class="select-wrap">
            <label>Game Type</label>
            <select [(ngModel)]="selectgametype" (change)="selectgame($event)">
              <option value="LIVECASINO" selected="selectgametype == true"> LIVE CASINO </option>
              <option value="SLOT" selected="selectgametype == true">SLOTS</option>
              <option value="TABLEGAME" selected="selectgametype == true">TABLE GAMES </option>
              <option value="SHOOTING" selected="selectgametype == true"> SHOOTING GAMES </option>
              <option value="INSTANTWIN" selected="selectgametype == true"> INSTANT WIN </option>
              <option value="SCRATCHCARD" selected="selectgametype == true"> SCRATCH CARD </option>
              <option value="VIRTUAL_SPORTS" selected="selectgametype == true"> VIRTUAL SPORTS </option>
            </select>
          </div>
          <!-- <div class="select-wrap">
              <label>Game Type</label>
              <select>

                <option>All</option>
                <option value="">  LIVE CASINO  </option>
                <option value=""> Live Popular</option>
                <option value="">Live Blackjack </option>
                <option value=""> Live Roulette </option>
                <option value=""> Others </option>
              </select>
            </div> -->
        </div>


        <div class="pagwrap-inner">
          <!-- <div class="casino-section">
              <div class="casinotitle mb-2">
                <div class="row align-items-center">
                  <div class="col-6"><h2>Casino</h2></div> 
                  <div class="col-6 text-right"></div>
                </div>
              </div>
              <div class="casinosldier">
                <div class="casinocontent-box">
                <div class="casinoimg">
                 
                  <img src="https://cdn.dreamcasino.live/evo_livespeedbaccarata.jpg">
                  <p class="gamename">Speed Baccarat A</p>
                </div>
              </div>
              <div class="casinocontent-box">
                <div class="casinoimg">                
                  <img src="https://cdn.dreamcasino.live/evo_livespeedbaccarata.jpg">
                  <p class="gamename">Speed Baccarat A</p>
                </div>
              </div>
              <div class="casinocontent-box">
                <div class="casinoimg">
                  
                  <img src="https://cdn.dreamcasino.live/evo_livespeedbaccarata.jpg">
                  <p class="gamename">Speed Baccarat A</p>
                </div>
              </div>
              <div class="casinocontent-box">
                <div class="casinoimg">
                  
                  <img src="https://cdn.dreamcasino.live/evo_livespeedbaccarata.jpg">
                  <p class="gamename">Speed Baccarat A</p>
                </div>
              </div>
              <div class="casinocontent-box">
                <div class="casinoimg">
                 
                  <img src="https://cdn.dreamcasino.live/evo_livespeedbaccarata.jpg">
                  <p class="gamename">Speed Baccarat A</p>
                </div>
              </div>
              <div class="casinocontent-box">
                <div class="casinoimg">
                
                  <img src="https://cdn.dreamcasino.live/evo_livespeedbaccarata.jpg">
                  <p class="gamename">Speed Baccarat A</p>
                </div>
              </div>
              </div>
     
              </div> -->

          <div class="casino-section-gridview">
            <div class="casinotitle mb-2">
              <div class="row align-items-center">
                <div class="col-6">
                  <h2 *ngIf="selectgametype=='LIVECASINO'">LIVE CASINO</h2>
                  <h2 *ngIf="selectgametype=='SLOT'">SLOTS</h2>
                  <h2 *ngIf="selectgametype=='TABLEGAME'">TABLE GAMES</h2>
                  <h2 *ngIf="selectgametype=='TABLEGAMES'">SHOOTING GAMES</h2>
                  <h2 *ngIf="selectgametype=='INSTANTWIN'">INSTANT WIN</h2>
                  <h2 *ngIf="selectgametype=='SCRATCHCARD'">SCRATCH CARD</h2>
                  <h2 *ngIf="selectgametype=='VIRTUAL_SPORTS'">VIRTUAL SPORTS</h2>
                </div>
                <div class="col-6 text-right"></div>
              </div>
            </div>

            <div class="casinogrid" *ngIf="casino_data?.length>0">
              <div class="casinocontent-box" *ngFor="let x of casino_data">
                <div class="casinoimg" (click)="openCasino(x.id)">
                  <div class="gamelogo"><img src="assets/images/casino-evolution.svg"></div>
                  <img [src]="x.images[1].url">
                  <p class="gamename">{{x.name}}</p>
                </div>
              </div>
            </div>

          </div>

        </div>

        <!-- casino_footer_end -->
      </div>
      <div *ngIf="res_msg">
        <h1 id="err_msg">{{res_msg}}</h1>
      </div>

    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>